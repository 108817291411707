import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AppState } from '@core/store';
import {
  selectConsultantDataCanNotBeModified,
  selectIsFinalizeButtonEnabled,
  selectPaymentStepSkipped,
  selectStepProcessing,
} from '@core/store/start-now-app';
import { Store } from '@ngrx/store';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';
import { StepperBaseComponent } from '@shared/components/stepper-base/stepper-base.component';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { StartNowAppStep } from '../../enums/start-now-app-step.enum';
import { StartNowStepBaseComponent } from '../start-now-app-step-base/start-now-step-base.component';
@Component({
  selector: 'app-start-now-app-stepper',
  templateUrl: './start-now-app-stepper.component.html',
  styleUrls: ['./start-now-app-stepper.component.scss'],
})
export class StartNowStepperComponent
  extends StepperBaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() initStep: number;
  @Input() steps: NavigationStep[];
  @Output() moveBackToInitialNavigationPosition: EventEmitter<void> = new EventEmitter();
  @Output() navigateToStep: EventEmitter<number> = new EventEmitter<number>();

  /** Based on the nav implementation just one of the nav item is loaded in the DOM, so just one
   * of the step referenced item is loaded */
  @ViewChild('step') currentStep: StartNowStepBaseComponent;

  stepProcessing$: Observable<boolean>;
  consultantDataCanNotBeModified$: Observable<boolean>;
  paymentStepSkipped$: Observable<boolean>;
  skipPayment: boolean = false;
  isFinalStep: boolean;
  isFinalizeButtonEnabled$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.stepProcessing$ = this.store$.select(selectStepProcessing).pipe(distinctUntilChanged());
    this.consultantDataCanNotBeModified$ = this.store$.select(selectConsultantDataCanNotBeModified);
    this.initialStepId = this.initStep;
    this.paymentStepSkipped$ = this.store$
      .select(selectPaymentStepSkipped)
      .pipe(map((skipPayment) => skipPayment));
    this.subscriptions.add(
      this.paymentStepSkipped$.subscribe((skipPayment) => {
        this.skipPayment = skipPayment;
        if (this.skipPayment && this.isFinalStep == true) {
          this.isFinalStep = false;
        }
      }),
    );
    this.isFinalizeButtonEnabled$ = this.store$.select(selectIsFinalizeButtonEnabled);
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.steps?.currentValue) {
      this.initSteps(this.steps);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  previousStepClick(): void {
    const previousStep: number = this.activeStepId;
    this.activeStepId = this.getPreviousStepId(this.activeStepId);

    if (this.skipPayment && this.isFinalStep == true) {
      this.isFinalStep = false;
    }

    if (previousStep == this.activeStepId) {
      this.moveBackToInitialNavigationPosition.emit();
    } else {
      this.stepChanged.emit(-1);
    }
  }

  nextStepClick(): void {
    this.currentStep.submitStep();
  }

  goToNextStep() {
    this.activeStepId = this.getNextStepId(this.activeStepId);
    if (
      this.skipPayment &&
      this.activeStepId + StartNowAppStep.ConfirmConsultant == StartNowAppStep.ConsultantAgreement
    ) {
      this.isFinalStep = true;
    } else {
      this.isFinalStep = false;
    }

    this.stepChanged.emit(1);
  }

  goToStep(step: number) {
    if (step <= StartNowAppStep.ConfirmConsultant) {
      this.moveBackToInitialNavigationPosition.emit();
    } else {
      this.steps.forEach((navigationStep) => {
        if (navigationStep.id === step) {
          this.navigateTo(navigationStep.orderNumber);
        }
      });
    }
    if (this.skipPayment && this.isFinalStep == true && step != StartNowAppStep.DoubleCheck) {
      this.isFinalStep = false;
    }
    this.navigateToStep.emit(step);
  }

  showConsultantList() {
    this.moveBackToInitialNavigationPosition.emit();
    this.navigateToStep.emit(StartNowAppStep.ConfirmConsultant);
  }
}
