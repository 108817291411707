/* eslint-disable no-unused-vars */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KushkiFormIdTypes } from '@core/enums/kushki-form-id-types.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { KushkiDropinUi } from '@core/models/dropin-instance.model';
import { AppInitService } from '@core/services/app-init.service';
import { LoggerService } from '@core/services/logger.service';
import { PaymentInfo } from '@core/store/payment/payment-state-models';
import { CardPaymentProviderBaseComponent } from '@payment/components/card-payment-provider-base-component';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-kushki',
  templateUrl: './kushki.component.html',
  styleUrls: ['./kushki.component.scss'],
})
export class KushkiComponent
  extends CardPaymentProviderBaseComponent<KushkiDropinUi>
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() amount: number;
  @Input() kushkiFormIdType: KushkiFormIdTypes = KushkiFormIdTypes.Card;
  @Input() hasKushkiSavedPayment: boolean = false;
  @Output() isPaymentApproved: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly KushkiFormId: string = 'kushkiForm';

  /** Have to adjust the kushki iframe height, because it cuts the bottom of the content */
  readonly KushkiHeightIncreasement = 10;

  kushki: KushkiDropinUi;

  private publicMerchantId: string;
  private isTestEnvironment: boolean;
  private kushkiKajitaUrl: string;
  private requestPaymentSuccessSubjectKushki: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  @ViewChild('KushkiDropinContainer') private kushkiDivContainer: ElementRef<HTMLElement>;
  @ViewChild('KushkiForm') private kushkiForm: ElementRef<HTMLFormElement>;

  @HostListener('window:message', ['$event'])
  kushkiIframeChnageListener(event: any): void {
    if (
      event.origin === this.kushkiKajitaUrl &&
      typeof event.data === 'string' &&
      event.data.includes('parameters')
    ) {
      const token = event.data.split(',')[0].split(':')[1];
      const paymentInfo: PaymentInfo = {
        nonce: token,
      };
      this.paymentHandler.handleRequestedPaymentMethodObject(null, paymentInfo);
      this.requestPaymentSuccessSubjectKushki.next(true);
      this.isPaymentMethodRequestable.emit(true);
      this.isPaymentApproved.emit(true);
    }

    if (typeof event.data === 'string' && event.data.includes('height')) {
      this.increaseKushkiContainerHeight();
    }
  }

  constructor(
    appInitService: AppInitService,
    loggerService: LoggerService,
    private renderer: Renderer2,
  ) {
    super(loggerService);
    this.publicMerchantId = appInitService.Settings.ec.kushkiPublicMerchantId;
    this.isTestEnvironment = appInitService.Settings.ec.kushkiKajitaIsTestEnvironment;
    this.kushkiKajitaUrl = appInitService.Settings.ec.kushkiKajitaUrl;
  }

  ngAfterViewInit(): void {
    this.initDropinUiByToken(PaymentProviderType.Kushki, '', '', this.kushkiFormIdType);
    if (this.hasKushkiSavedPayment) {
      this.requestPaymentSuccessSubjectKushki.next(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kushkiFormIdType?.currentValue && changes.kushkiFormIdType?.previousValue) {
      this.resetToken();
    }
    if (changes.hasKushkiSavedPayment?.currentValue) {
      this.requestPaymentSuccessSubjectKushki.next(true);
    }
  }

  ngOnDestroy(): void {
    this.isPaymentApproved.emit(false);
  }

  get dropInInstance(): KushkiDropinUi {
    return this.kushki;
  }

  get dropInContainer(): ElementRef<HTMLElement> {
    return this.kushkiDivContainer;
  }

  initDropinInstance(
    token: string,
    cardholderName: string,
    isCvvRequired?: boolean,
    isAvsRequired?: boolean,
    collectRiskDataFromInput?: boolean,
  ): void {
    if (token && this.publicMerchantId) {
      this.requestPaymentSuccessSubjectKushki.next(false);
      this.loading.emit(false);
      this.renderer.setAttribute(this.kushkiForm.nativeElement, 'action', 'javascript:void(0)');
      this.kushki = new KushkiCheckout({
        kformId: token,
        form: this.KushkiFormId,
        publicMerchantId: this.publicMerchantId,
        inTestEnvironment: this.isTestEnvironment,
        amount: {
          subtotalIva: 0,
          subtotalIva0: this.amount,
          iva: 0,
          currency: 'MXN',
        },
      });
      this.dropInComponentInitialized.emit(this);
      this.checkInputFocusability();
    }
  }

  resetToken(cardholderFirstName?: string, cardholderLastName?: string): void {
    this.requestPaymentSuccessSubjectKushki.next(false);
    this.paymentHandler.resetToken();
    this.kushkiForm?.nativeElement.replaceChildren();
    this.isPaymentApproved.emit(false);
    this.initDropinUiByToken(PaymentProviderType.Kushki, '', '', this.kushkiFormIdType);
  }

  getCardPaymentInputs(): Array<HTMLElement> | undefined {
    return [];
  }

  checkInputFocusability(): void {
    const iframe = this.kushkiForm?.nativeElement.querySelector('iframe');
    if (!iframe) return;
    if (this.disableInput) {
      iframe.setAttribute('tabindex', '-1');
    } else {
      iframe.removeAttribute('tabindex');
    }
  }

  requestPaymentMethodObject(): Observable<boolean> {
    return this.requestPaymentSuccessSubjectKushki.asObservable();
  }

  /** As the dropin container is ready for user interaction by the necessary input data (amount, card billing address),
   * have to increase the iframe height */
  private increaseKushkiContainerHeight(): void {
    const iframe = this.kushkiForm?.nativeElement.querySelector('iframe');
    if (!iframe) return;
    // have to wait until the height is set on the iframe
    setTimeout(() => {
      const iFrameHeight = iframe.getAttribute('height');
      if (iFrameHeight) {
        const numberHeight = Number(iFrameHeight) + this.KushkiHeightIncreasement;
        iframe.setAttribute('height', numberHeight.toString());
      }
    });
  }
}
