<div class="row" *ngIf="checkoutInfoCards$ | async as infoCards">
  <div class="col summary-content">
    <div class="row mb-md-3">
      <div
        [ngClass]="{
          'text-center': true,
          col: !isRegistrationSectionVisible,
          'col-12 col-md-6': isRegistrationSectionVisible
        }"
      >
        <h3 i18n>Thank you for your order!</h3>
        <p i18n
          >Your order has been confirmed and you will receive an order confirmation email shortly.
        </p>
        <div *ngIf="(vouchers$ | async)?.length > 0">
          <img class="pay-by-cash-icon" [src]="PaymentImageSrc.oxxo" />
          <div class="my-3">
            <strong class="title" i18n>Download vouchers</strong>
            <div class="d-flex flex-wrap justify-content-center">
              <ng-container *ngFor="let voucher of vouchers$ | async; index as i">
                <button class="btn btn-link btn-sm mr-1" (click)="download(voucher.token)">
                  <span class="mr-1" i18n>Voucher</span>
                  <span>{{ i + 1 }}</span>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isRegistrationSectionVisible" class="col-12 col-md-6">
        <app-checkout-register-user></app-checkout-register-user>
      </div>
    </div>

    <ng-container>
      <hr class="my-0 d-lt-none" />
      <div class="row d-none d-md-flex">
        <div class="col-12 col-md-6 px-md-0 pr-md-5 b-right px-3">
          <div class="my-1 px-md-0 py-2">
            <div class="ph-row" *ngIf="orderNumber$ | async as orderNumber">
              <span class="text-uppercase text-double-dot" i18n>Order Number</span>
              <span class="font-weight-bold ml-1">{{ orderNumber }}</span>
              <button
                type="button"
                class="btn btn-link ml-auto text-right p-0"
                (click)="printOrderSummary(orderNumber)"
              >
                <i class="bi bi-printer print-icon"></i>
                <span class="text-uppercase print-text" i18n>Print</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 px-md-0 pl-md-5">
          <div class="d-flex justify-content-between my-1 px-md-0 py-2">
            <div *ngIf="infoCards.partyInfo.consultant" class="ph-row">
              <span class="text-uppercase text-double-dot" i18n>Consultant</span>
              <span class="font-weight-bold ml-1">{{ infoCards.partyInfo.consultant }}</span>
            </div>
            <div *ngIf="infoCards.partyInfo.host" class="ph-row">
              <span class="text-uppercase text-double-dot" i18n>Host</span>
              <span class="font-weight-bold ml-1">{{ infoCards.partyInfo.host }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-md-none" *ngIf="orderNumber$ | async as orderNumber">
        <div class="row my-3">
          <div class="col-12">
            <div class="d-flex summary-row justify-content-between">
              <span class="summary-text" i18n>Order summary</span>
              <button
                type="button"
                class="btn btn-link p-0"
                (click)="printOrderSummary(orderNumber)"
              >
                <i class="bi bi-printer print-icon summary-text"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 my-2">
            <div class="content-wrapper">
              <div class="ph-row">
                <span class="text-uppercase" i18n>Order Number</span>
                <span>{{ orderNumber }}</span>
              </div>
            </div>
          </div>

          <app-order-lines
            class="col-12 my-2"
            [cart]="submittedCart$ | async"
            [disableHeader]="true"
            [readOnly]="true"
          ></app-order-lines>

          <app-info-card class="col-12" [data]="infoCards.partyInfo"></app-info-card>
        </div>
      </div>

      <div>
        <hr class="my-0 d-lt-none" />
        <div class="row">
          <app-info-card
            class="col-12 col-md-6 b-right px-3 px-md-0 pr-md-5"
            [data]="infoCards.customerInfo"
          ></app-info-card>

          <app-info-card
            class="col-12 col-md-6 px-3 px-md-0 pl-md-5"
            [data]="infoCards.shippingInfo"
          ></app-info-card>
        </div>
        <hr class="my-0 d-lt-none" />
        <div class="row">
          <app-info-card
            class="col-12 col-md-6 b-right px-3 px-md-0 pr-md-5"
            [data]="infoCards.billingInfo"
          ></app-info-card>

          <section
            *ngIf="paymentType$ | async as paymentType"
            class="col-12 col-md-6 px-3 px-md-0 pl-md-5"
          >
            <app-info-card
              *ngIf="
                paymentType === PaymentType.CreditOrDebitCard ||
                paymentType === PaymentType.PayPal ||
                paymentType === PaymentType.BankTransfer
              "
              [data]="infoCards.paymentInfo"
            ></app-info-card>
          </section>
        </div>
        <hr class="my-0 d-lt-none" />
      </div>
    </ng-container>

    <div class="row font-size-smaller d-lt-none text-uppercase font-weight-bold mt-4">
      <div class="col-md-5">
        <p class="text-uppercase weight-700" i18n>Product(s)</p>
      </div>
      <div class="col-md-2">
        <p class="text-uppercase weight-700" i18n>Price</p>
      </div>
      <div class="col-md-2">
        <p class="text-uppercase weight-700" i18n>Qty</p>
      </div>
      <div class="col-md-1">
        <p class="text-uppercase weight-700" i18n>Total</p>
      </div>
    </div>
    <hr class="my-0 d-lt-none" />
    <div class="row d-lt-none">
      <app-order-lines
        class="col"
        [cart]="submittedCart$ | async"
        [disableHeader]="true"
        [readOnly]="true"
      ></app-order-lines>
    </div>
    <hr class="my-0 d-lt-none" />
    <div class="row">
      <app-cart-totals class="col" [cart]="submittedCart$"></app-cart-totals>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="container text-center">
    <img src="assets/images/misc/phLoading.gif" alt="loading" />
  </div>
</ng-template>
