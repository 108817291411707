<div class="saved-bank-transfer-container">
  <div class="d-flex justify-content-between px-2">
    <div class="saved-bank-transfer-header">
      <span i18n>
        Saved Payment
        <i class="bi bi-check"></i>
      </span>
    </div>
    <div
      *ngIf="canChangeSavedBankTransfer"
      class="change-bank-transfer"
      (click)="resetPaymentClick()"
    >
      <span i18n>Change</span>
    </div>
  </div>
</div>
