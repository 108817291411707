<ng-container [ngSwitch]="paymentProviderType">
  <div
    class="d-flex justify-content-center"
    *ngIf="isPaymentHandlerLoading && !showAddPaymentButtonForKushki"
  >
    <app-ph-spinner class="mt-5" width="100px"> </app-ph-spinner>
  </div>
  <div
    class="drop-in-container-wrapper"
    [ngClass]="{ 'disable-input': disableInput }"
    [class.hidden]="isPaymentHandlerLoading && !showAddPaymentButtonForKushki"
  >
    <app-braintree
      *ngSwitchCase="PaymentProviderType.Braintree"
      class="w-100"
      [cardholderName]="cardholderName"
      [disableInput]="disableInput"
      [paymentHandler]="paymentHandler"
      [createOnly]="createOnly"
      (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
      (dropInComponentInitialized)="onDropInComponentInitialized($event)"
      (cardAttempt)="forwardCardAttempt($event)"
      (loading)="handleLoadingChange($event)"
    ></app-braintree>
    <app-pay-pal
      *ngSwitchCase="PaymentProviderType.PayPal"
      class="d-block"
      [ngClass]="payPalTopMargin"
      [paymentHandler]="paymentHandler"
      [disableInput]="disableInput"
      [isCardPaymentEnabled]="isPayPalCardPaymentEnabled"
      (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
      (dropInComponentInitialized)="onDropInComponentInitialized($event)"
      (isPaymentApproved)="paymentApproved($event)"
      (cardAttempt)="forwardCardAttempt($event)"
      (loading)="handleLoadingChange($event)"
    ></app-pay-pal>
    <app-nexio
      *ngSwitchCase="PaymentProviderType.Nexio"
      [cardholderName]="cardholderName"
      [paymentHandler]="paymentHandler"
      [disableInput]="disableInput"
      (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
      (dropInComponentInitialized)="onDropInComponentInitialized($event)"
      (loading)="handleLoadingChange($event)"
    >
    </app-nexio>
    <ng-container *ngSwitchCase="PaymentProviderType.Kushki">
      <div *ngIf="showAddPaymentButtonForKushki; else kushkiPayment">
        <button
          class="btn btn-primary text-uppercase add-card-button"
          (click)="addPaymentCardButtonClick()"
          [disabled]="(paymentHandler.amount$ | async) <= 0"
        >
          {{ addPaymentButtonText }}
        </button>
      </div>
      <ng-template #kushkiPayment>
        <app-kushki
          *ngSwitchCase="PaymentProviderType.Kushki"
          [amount]="paymentHandler.amount$ | async"
          [paymentHandler]="paymentHandler"
          [disableInput]="disableInput"
          [kushkiFormIdType]="kushkiFormIdType"
          [hasKushkiSavedPayment]="hasKushkiSavedPayment"
          (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
          (dropInComponentInitialized)="onDropInComponentInitialized($event)"
          (loading)="handleLoadingChange($event)"
          (isPaymentApproved)="paymentApproved($event)"
        ></app-kushki>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
