<div class="my-3 px-md-0">
  <div *ngIf="data?.title" class="title">
    <span>{{ data?.title }}</span>
    <a class="pointer" *ngIf="data?.readOnly === false" (click)="clickModify()">
      <i class="bi bi-pencil-square"></i>
    </a>
  </div>
  <div class="content-wrapper">
    <div *ngIf="data?.name" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Name</span>
      <span class="font-weight-bold">{{ data?.name }}</span>
    </div>
    <div *ngIf="data?.email" class="ph-row">
      <span i18n class="text-uppercase text-double-dot text-left">Email</span>
      <span class="font-weight-bold" data-private>{{ data?.email }}</span>
    </div>
    <div *ngIf="data?.addressLine1 && data?.addressLine2" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Address</span>
      <span class="d-none d-md-inline font-weight-bold" data-private>{{ data?.addressLine1 }}</span>
      <span class="d-none d-md-block font-weight-bold ml-3" data-private>
        {{ data?.addressLine2 }}
      </span>
      <div class="d-md-none">
        <span class="ml-2 d-block font-weight-bold" data-private>{{ data?.addressLine1 }}</span>
        <span class="ml-2 d-block font-weight-bold" data-private>{{ data?.addressLine2 }}</span>
      </div>
    </div>
    <div *ngIf="data?.phone" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Phone</span>
      <span class="font-weight-bold" data-private>{{ data?.phone }}</span>
    </div>
    <div *ngIf="data?.cardNumber" class="ph-row">
      <span i18n class="text-uppercase text-double-dot text-left">Card number</span>
      <span class="font-weight-bold">{{ data?.cardNumber }}</span>
    </div>
    <div *ngIf="data?.clabe" class="ph-row">
      <span i18n class="text-uppercase text-double-dot text-left">Clabe</span>
      <span class="font-weight-bold">{{ data?.clabe }}</span>
    </div>
    <div *ngIf="data?.consultant" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Consultant</span>
      <span class="font-weight-bold">{{ data?.consultant }}</span>
    </div>
    <div *ngIf="data?.customer" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Customer</span>
      <span class="font-weight-bold">{{ data?.customer }}</span>
    </div>
    <div *ngIf="data?.host" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Host</span>
      <span class="font-weight-bold">{{ data?.host }}</span>
    </div>
    <div *ngIf="data?.vouchers" class="ph-row d-flex flex-wrap justify-content-start">
      <ng-container *ngFor="let voucher of data.vouchers; index as i">
        <button class="btn btn-link btn-sm mr-1" (click)="download(voucher)">
          <span class="mr-1" i18n>Voucher</span>
          <span>{{ i + 1 }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
