import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PaymentImageSrc } from '@core/constants/payment.consts';
import { PaymentType } from '@core/enums/payment-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  resetClientToken,
  updatePaymentProviderType,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { getPaymentProviderType } from '@payment/payment-handler/payment-provider.utils';
import { PaymentTypeChangedModalResult } from '../payment-type-changed-modal/payment-type-changed-modal-result.model';

@Component({
  selector: 'app-payment-type-selector',
  templateUrl: './payment-type-selector.component.html',
  styleUrls: ['./payment-type-selector.component.scss'],
})
export class PaymentTypeSelectorComponent implements OnChanges {
  @Input() selectedPaymentType: PaymentType;
  @Input() isStepProcessing: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showVoucherPayment: boolean = true;
  @Input() showBankTransferPayment: boolean = true;
  @Input() hasSavedPayment: boolean = false;
  @Output() selectedPaymentTypeChange: EventEmitter<PaymentType> = new EventEmitter<PaymentType>();
  @Output() changePaymentType: EventEmitter<PaymentTypeChangedModalResult> =
    new EventEmitter<PaymentTypeChangedModalResult>();

  readonly PaymentImageSrc = PaymentImageSrc;
  readonly PaymentType = PaymentType;

  constructor(
    private store$: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    private appInitService: AppInitService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.cdRef.detectChanges();
    }
  }

  onChangePaymentType(paymentType: PaymentType) {
    if (!this.hasSavedPayment) {
      const provider = getPaymentProviderType(
        paymentType,
        this.appInitService.Settings.sna.isNexioCardPaymentEnabled,
      );
      this.store$.dispatch(updatePaymentProviderType({ provider }));
      this.store$.dispatch(resetClientToken());
    }

    this.selectedPaymentTypeChange.emit(paymentType);
  }
}
