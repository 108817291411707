import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConsultantFinderModalComponent } from '@consultant/consultant-finder-modal/consultant-finder-modal.component';
import { OrderSubmissionErrorMessages } from '@core/constants/order-submission-error-messages';
import { PFASSTATE } from '@core/constants/pfas-state';
import { PaymentType } from '@core/enums/payment-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { WebsiteMode } from '@core/enums/website-mode.enum';
import { CheckoutInfoCards } from '@core/models/checkout.model';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectCart } from '@core/store/cart';
import { Cart, OrderLine } from '@core/store/cart/cart-state-models';
import { selectPersonalInfo } from '@core/store/checkout';
import {
  selectCurrentConsultant,
  selectCurrentConsultantParties,
  selectFetchCurrentConsultantSuccessfulStatus,
} from '@core/store/consultant';
import { Consultant, Party } from '@core/store/consultant/consultant-state-models';
import { resetFetchCurrentConsultantSuccessfulStatus } from '@core/store/consultant/consultant.actions';
import { selectIsSubmitDisabled, selectSubmissionErrorType } from '@core/store/order';
import { resetIsSubmitDisabled } from '@core/store/order/order.actions';
import { selectCreateVoucherError } from '@core/store/voucher';
import { resetVoucherErrors } from '@core/store/voucher/voucher.actions';
import { selectWebsiteMode } from '@core/store/website-mode';
import { select, Store } from '@ngrx/store';
import {
  createPersonalInfoCard,
  createShippingInfoCard,
} from '@shared/utils/create-shipping-info-card-attributes';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { getVoucherErrorMessage } from '@shared/utils/voucher-error-utils';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppCartPfasPopupComponent } from '../app-cart-pfas-popup/app-cart-pfas-popup.component';
import { PartyBoxComponent } from './party-box/party-box.component';

@Component({
  selector: 'app-checkout-order-summary',
  templateUrl: './checkout-order-summary.component.html',
  styleUrls: ['../checkout-slide.shared.scss', './checkout-order-summary.component.scss'],
})
export class CheckoutOrderSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Output() clickNext: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickBack: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('partyBox', { static: true }) partyBoxComponent: PartyBoxComponent;

  readonly ErrorTypes = PhExceptionErrorType;
  readonly ConsultantFinderText: string = $localize`To process your order we first
  need to connect you with a Consultant. If you know of or have a Consultant already,
  we can help you look them up. If you don't know a Consultant, we can help you find
  one in your area.`;

  cart$: Observable<Cart>;
  createVoucherErrorMessage$: Observable<string>;
  currentConsultant$: Observable<Consultant>;
  zipCode$: Observable<string>;
  isSubmitDisabled$: Observable<boolean>;
  submissionErrorMessage$: Observable<string>;
  paymentType: PaymentType;
  checkoutInfoCards$: Observable<CheckoutInfoCards>;
  fetchConsultantSuccessStatus$: Observable<SuccessfulStatus>;
  consultantParties$: Observable<Party[]>;
  isPfasProduct$: Observable<boolean>;
  pfasOrderLines$: Observable<OrderLine[]>;
  isPfaState$: Observable<boolean>;

  @ViewChild('pfasModal') private cartPfasModel: AppCartPfasPopupComponent;
  @ViewChild('consultantFinder') private consultantFinderModal: ConsultantFinderModalComponent;
  private subscriptions: Subscription = new Subscription();
  private isConsultantSelectedOnModal: boolean = false;
  private isPfasOpened: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.initCheckoutInfoCards();
    this.currentConsultant$ = this.store$.select(selectCurrentConsultant);
    this.isSubmitDisabled$ = this.store$.select(selectIsSubmitDisabled);
    this.fetchConsultantSuccessStatus$ = this.store$.select(
      selectFetchCurrentConsultantSuccessfulStatus,
    );
    this.cart$ = this.store$.select(selectCart).pipe(filter((x) => !!x));
    this.zipCode$ = this.store$
      .select(selectPersonalInfo)
      .pipe(select((personalInfo) => personalInfo.shippingAddress.address.zipCode));
    this.isPfaState$ = this.store$
      .select(selectPersonalInfo)
      .pipe(select((personalInfo) => personalInfo.shippingAddress.address.state === PFASSTATE));
    this.pfasOrderLines$ = this.store$.select(selectCart).pipe(
      filter((cart) => !!cart),
      map(
        (x) => x?.orderLines.filter((orderLine) => orderLine).filter((d) => d.isPFASItem === true),
      ),
    );
    this.consultantParties$ = this.store$.select(selectCurrentConsultantParties);
    this.reopenConsultantFinder();
    this.getCreateVoucherError();
    this.getSubmissionErrorMessage();
    this.initIsPfaProduct();
  }

  ngAfterViewInit(): void {
    this.initConsultantFinder();
    this.initPfasModal();
    this.initPfasModal();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetIsSubmitDisabled());
    this.store$.dispatch(resetVoucherErrors());
    this.subscriptions.unsubscribe();
  }

  next(): void {
    this.partyBoxComponent.partyFormGroup.markAllAsTouched();
    if (this.partyBoxComponent.partyFormGroup.valid) {
      this.clickNext.emit();
      scrollToTop();
    }
  }

  initIsPfaProduct(): void {
    this.isPfasProduct$ = this.store$.select(selectCart).pipe(
      filter((cart) => !!cart),
      map(
        (x) =>
          !!x?.orderLines.filter((orderLine) => orderLine).filter((d) => d.isPFASItem === true)
            .length,
      ),
    );
  }

  back(): void {
    this.clickBack.emit();
  }

  consultantSelected(): void {
    this.isConsultantSelectedOnModal = true;
  }

  private initPfasModal() {
    this.subscription.add(
      combineLatest([this.pfasOrderLines$, this.isPfaState$]).subscribe(([data, isPfaState]) => {
        if (data.length > 0 && !this.isPfasOpened && isPfaState) {
          this.isPfasOpened = true;
          this.cartPfasModel.openModal(data);
        }
      }),
    );
  }

  private initConsultantFinder() {
    this.subscriptions.add(
      this.store$
        .select(selectWebsiteMode)
        .pipe(
          take(1),
          filter((websiteMode) => websiteMode === WebsiteMode.corporate),
        )
        .subscribe(() => this.consultantFinderModal.open()),
    );
  }

  private getCreateVoucherError(): void {
    this.createVoucherErrorMessage$ = this.store$
      .select(selectCreateVoucherError)
      .pipe(
        map((error) =>
          getVoucherErrorMessage(
            error?.errors,
            error?.email,
            this.appInitService.Settings.ec.voucherLimits,
          ),
        ),
      );
  }

  private getSubmissionErrorMessage(): void {
    this.submissionErrorMessage$ = this.store$
      .select(selectSubmissionErrorType)
      .pipe(map((errorType) => OrderSubmissionErrorMessages[errorType]));
  }

  private initCheckoutInfoCards(): void {
    this.checkoutInfoCards$ = this.store$.select(selectPersonalInfo).pipe(
      map((personalInfo) => ({
        customerInfo: createPersonalInfoCard(personalInfo.billingCustomer, false),
        shippingInfo: createShippingInfoCard(personalInfo, false),
      })),
    );
  }

  private reopenConsultantFinder(): void {
    this.subscriptions.add(
      this.fetchConsultantSuccessStatus$
        .pipe(
          filter(
            (status) => status === SuccessfulStatus.Failed && this.isConsultantSelectedOnModal,
          ),
        )
        .subscribe(() => {
          this.isConsultantSelectedOnModal = false;
          this.consultantFinderModal.open();
          this.store$.dispatch(resetFetchCurrentConsultantSuccessfulStatus());
        }),
    );
  }
}
