import { Country } from '@core/enums/country.enum';
import { Currency } from '@core/enums/currency.enum';
import { LogLevel } from '@core/enums/log-level.enum';

export const environment = {
  production: true,
  ecommerceBackendUri: 'https://api.princesshouse.com/',
  consultantCorner2Uri: 'https://newcorner.princesshouse.com',
  startNowAppBackendUri: 'https://newcorner-api.princesshouse.com/startnowapp/',
  ecommerceBackendClientId: 'storefrontApp',
  ecommerceBackendClientSecret: 'storefrontSecret',
  country: Country.Usa,
  currency: Currency.Usa,
  languages: [
    { code: 'en-US', lid: 0 },
    { code: 'es-US', lid: 1 },
  ],
  nexioPaymentApiUri: undefined,
  ec1StartYourBusinessUrl: 'https://www.princesshouse.com/start-now-app/',
  agreementHtmlBaseUrl: 'https://ststartnowprod.blob.core.windows.net',
  storeLocatorUrl: undefined,
  gtmContainerId: 'GTM-MJZWFM3',
  logRocketId: '0zykvg',
  appcuesId: '134956',
  cookieBotId: '85f086ca-3fb5-4772-9f4d-57afcb11d5cd',
  environmentName: 'ec_us_prod_online',
  recaptchaSiteKey: '6LedbEEmAAAAAJcwGxC1GGiuPahqJACARyeFll26',
  highestConsolLogLevel: LogLevel.Error,
  b2c: {
    tenant: 'phecprodb2c',
    domain: 'ec-login.princesshouse.com',
    clientId: '54e04e11-c3e1-4302-9848-6aef3d476c03',
    customerScope:
      'https://phecprodb2c.onmicrosoft.com/1d67827b-b6c2-4722-915f-c9554b20d707/customer',
  },
  feature: {
    product: true,
    consultantFinder: true,
    consultantCorner: true,
    footerSocial: true,
    footerExtra: true,
    contactUsLocation: true,
    voucherPaymentEC: false,
    voucherPaymentSNA: false,
    bankTransferEc: false,
    bankTransferSna: false,
    bankTransferPoPayment: false,
    productReview: false,
    isAccessibeEnabled: true,
    mobileLogin: false,
  },
  tel: {
    mask: '(000) 000 0000',
    prefix: '+1 ',
    customerService: {
      prefix: '1',
      number: '508-823-6800',
    },
    career: {
      prefix: '1',
      number: '800-622-0039',
    },
    homeOfficeMember: {
      prefix: '1',
      number: '508-823-0711',
    },
  },
  datadog: {
    applicationId: '2dcfb45a-e654-44f7-a527-4722b8e2ed5a',
    clientToken: 'pubd98083516de65615e79bc19b319f9235',
    site: 'datadoghq.com',
    service: 'ec-2.0---usa-prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  },
};
