<div class="row justify-content-between">
  <div class="col col-md-5">
    <button
      class="btn btn-outline-dark text-uppercase w-100"
      i18n
      (click)="previousStepClick()"
      [disabled]="nextProcessing || isBackButtonDisabled"
    >
      Back
    </button>
  </div>
  <div class="col col-md-5">
    <button
      class="btn btn-primary text-uppercase w-100"
      [disabled]="nextProcessing || isNextButtonDisabled || isNextButtonDisabled"
      (click)="nextStepClick()"
    >
      {{ isFinalStep ? Finalize : Next }}
      <div class="spinner-border spinner-border-sm" *ngIf="nextProcessing" role="status">
        <span class="sr-only" i18n>Loading...</span>
      </div>
    </button>
  </div>
</div>
