import { PhErrorResponse } from '@core/dto/ph-error';
import {
  ClientTokenRequest,
  CreateOrderRequest,
  CreateOrderResponse,
  CreatePaymentMethodRequest,
  CreatePaymentMethodResponse,
  CreateVoucherRequest,
  FindConsultantByAreaRequest,
  FindConsultantByCodeRequest,
  FindConsultantByZipCodeRequest,
  OrderSummaryResponse,
  PhoneNumberValidationRequest,
  PhoneNumberValidationResponse,
  ReceiveVouchersRequest,
  ReceiveVouchersResponse,
  SaveLogCardAttemptRequest,
  StarterKitDto,
  SubmitConsultantRequest,
  SubmitConsultantResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
  ValidateRegistrationCodeRequest,
  ValidateRegistrationCodeResponse,
  VerifyAddressResponse,
} from '@core/dto/start-now-app.dto';
import { CongratulationType } from '@core/enums/congratulation-type.enum';
import { LanguagePreferenceType } from '@core/enums/language-preference-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { PhoneNumberType } from '@core/enums/phone-number-type.enum';
import { StartNowPaymentType } from '@core/enums/start-now-app.enum';
import { createAction, props } from '@ngrx/store';
import { Consultant } from '../consultant/consultant-state-models';
import {
  ClientTokenResponse,
  CreatedVoucherPaymentMethod,
  PaymentInfo,
} from '../payment/payment-state-models';
import {
  AdditionalBillingInformation,
  Address,
  CardPaymentInfo,
  ContactInfo,
  CountryState,
  FoundConsultantsByZipCode,
  SaveConsultantDataResult,
  SaveUserInfoResult,
  UserInfo,
  VoucherPaymentInfo,
} from './start-now-app-state-models';

export enum StartNowAppActionTypes {
  validateReCaptcha = '[StartNowApp] Validate ReCaptcha',
  validateReCaptchaSuccess = '[StartNowApp] Validate ReCaptcha success',
  validateReCaptchaFailure = '[StartNowApp] Validate ReCaptcha failure',
  resetReCaptchaValidation = '[StartNowApp] Reset ReCaptcha validation',
  stepProcessing = '[StartNowApp] Step is processing',
  findConsultantByArea = '[StartNowApp] Find consultant by area',
  findConsultantByAreaNextPage = '[StartNowApp] Find consultant by area next page',
  findConsultantByZipCodeNextPage = '[StartNowApp] Find consultant by zip code next page',
  findConsultantByConsultantCode = '[StartNowApp] Find consultant by code',
  findConsultantSuccess = '[StartNowApp] Find consultant success',
  findConsultantFailure = '[StartNowApp] Find consultant failure',
  findConsultantByZipCode = '[StartNowApp] Find consultant by zip code',
  findConsultantByZipCodeSuccess = '[StartNowApp] Find consultant by zip code success',
  findConsultantByZipCodeFailure = '[StartNowApp] Find consultant by zip code failure',
  resetFindConsultantByZipCodeErrorType = '[StartNowApp] Reset consultant by zip code error type',
  validateRegistrationCode = '[StartNowApp] Validate registration code',
  validateRegistrationCodeSuccess = '[StartNowApp] Validate registration code success',
  validateRegistrationCodeFailure = '[StartNowApp] Validate registration code failure',
  updateRegistrationCode = '[StartNowApp] Update registration code',
  updateRegistrationCodeStepSkipped = '[StartNowApp] Update registration code step skip',
  resetRegistrationCodeStepSkipped = '[StartNowApp] Reset registration code step skip',
  resetRegistrationCodeValidity = '[StartNowApp] Reset registration code validity',
  resetRegistrationCode = '[StartNowApp] Reset registration code',
  resetAddressValidationStep = '[StartNowApp] Reset address validation step',
  resetFindConsultant = '[StartNowApp] Reset find consultant',
  resetNoFindConsultantModal = '[StartNowApp] Reset no find consultant modal',
  resetFirstConsultants = '[StartNowApp] Reset first consultants',
  updateSelectedConsultant = '[StartNowApp] Update selected consultant',
  resetSelectedConsultant = '[StartNowApp] Reset selected consultant',
  updateStartNowAppUserInfo = '[StartNowApp] Update user info',
  updateStartNowAppUserInfoSuccess = '[StartNowApp] Update user info success',
  updateStartNowAppUserInfoFailure = '[StartNowApp] Update user info failure',
  resetAboutYouStep = '[StartNowApp] Reset about you step',
  emailUniquenessCheck = '[StartNowApp] Check email uniqueness',
  emailUniquenessCheckSuccess = '[StartNowApp] Check email uniqueness success',
  emailUniquenessCheckFailure = '[StartNowApp] Check email uniqueness failure',
  resetEmailValidity = '[StartNowApp] Reset email validity',
  phoneNumberUniquenessCheck = '[StartNowApp] Check phone number uniqueness',
  phoneNumberUniquenessCheckSuccess = '[StartNowApp] Check phone number uniqueness success',
  phoneNumberUniquenessCheckFailure = '[StartNowApp] Check phone number uniqueness failure',
  resetPhoneNumberValidity = '[StartNowApp] Reset phone numbers validity',
  updateStartNowAppContactInfo = '[StartNowApp] Update contact info',
  updateStartNowAppContactInfoSuccess = '[StartNowApp] Update contact info success',
  updateStartNowAppContactInfoFailure = '[StartNowApp] Update contact info failure',
  resetContactStep = '[StartNowApp] Reset contact step',
  updateStartNowAppAddressInfo = '[StartNowApp] Update address info',
  updateStartNowAppAddressInfoSuccess = '[StartNowApp] Update address info success',
  updateStartNowAppAddressInfoFailure = '[StartNowApp] Update address info failure',
  addAddress = '[StartNowApp] Add address to address list',
  fetchStartNowAppStarterKits = '[StartNowApp] Fetch starter kits',
  fetchStartNowAppStarterKitsSuccess = '[StartNowApp] Fetch starter kits success',
  fetchStartNowAppStarterKitsFailure = '[StartNowApp] Fetch starter kits failure',
  fetchDepositPaymentValue = '[StartNowApp] Fetch deposit payment value',
  fetchDepositPaymentValueSuccess = '[StartNowApp] Fetch deposit payment value success',
  fetchDepositPaymentValueFailure = '[StartNowApp] Fetch deposit payment value failure',
  updateIsDepositPayment = '[StartNowApp] Update isDepositPayment',
  updateStartNowAppSelectedStarterKit = '[StartNowApp] Update selected starter kit',
  updateStartNowAppSelectedStarterKitSuccess = '[StartNowApp] Update selected starter kit success',
  updateStartNowAppSelectedStarterKitFailure = '[StartNowApp] Update selected starter kit failure',
  fetchOrderSummary = '[StartNowApp] Fetch order summary',
  fetchOrderSummarySuccess = '[StartNowApp] Fetch order summary success',
  fetchOrderSummaryFailure = '[StartNowApp] Fetch order summary failure',
  resetOrderSummary = '[StartNowApp] Reset order summary',
  checkIfNewPaymentRequired = '[StartNowApp] Check if new payment required',
  checkIfNewPaymentRequiredSuccess = '[StartNowApp] Check if new payment required success',
  checkIfNewPaymentRequiredFailure = '[StartNowApp] Check if new payment required failure',
  resetStarterKitStep = '[StartNowApp] Reset starter kit step',
  validateAddress = '[StartNowApp] Validate address',
  validateAddressSuccess = '[StartNowApp] Validate address success',
  validateAddressFailure = '[StartNowApp] Validate address failure',
  updateAddressValidationModalValidity = '[StartNowApp] Update address validation modal validity',
  resetAddressValidationModal = '[StartNowApp] Reset address validation modal',
  fetchCountryStates = '[StartNowApp] Fetch country states',
  fetchCountryStatesSuccess = '[StartNowApp] Fetch country states success',
  fetchCountryStatesFailure = '[StartNowApp] Fetch country states failure',
  fetchClientToken = '[StartNowApp] Fetch Client Token',
  fetchClientTokenSuccess = '[StartNowApp] Fetch Client Token Success',
  fetchClientTokenFailure = '[StartNowApp] Fetch Client Token Failure',
  resetClientToken = '[StartNowApp] Reset Client Token',
  storePaymentType = '[StartNowApp] Store Payment Type',
  storeCardPaymentInfo = '[StartNowApp] Store Card Payment Info',
  storeVoucherPaymentInfo = '[StartNowApp] Store Voucher Payment Info',
  storeExternalPaymentInfo = '[StartNowApp] Store External Payment Info',
  storeExternalPaymentInfoSuccess = '[StartNowApp] Store External Payment Info Success',
  storeExternalPaymentInfoFailure = '[StartNowApp] Store External Payment Info Failure',
  updatePaymentProviderType = '[StartNowApp] Update Payment Provider Type',
  createOrder = '[StartNowApp] Create Order',
  createOrderSuccess = '[StartNowApp] Create Order Success',
  createOrderFailure = '[StartNowApp] Create Order Failure',
  updateOrder = '[StartNowApp] Update Order',
  updateOrderSuccess = '[StartNowApp] Update Order Success',
  updateOrderFailure = '[StartNowApp] Update Order Failure',
  createPaymentMethod = '[StartNowApp] Create Payment Method',
  createPaymentMethodSuccess = '[StartNowApp] Create Payment Method Success',
  createPaymentMethodFailure = '[StartNowApp] Create Payment Method Failure',
  resetPaymentMethod = '[StartNowApp] Reset Payment Method',
  updateAdditionalBillingInformation = '[StartNowApp] Update Additional Billing Information',
  resetAdditionalBillingInformation = '[StartNowApp] Reset Additional Billing Information',
  createVoucher = '[StartNowApp] Create Voucher',
  createVoucherSuccess = '[StartNowApp] Create Voucher Success',
  createVoucherFailure = '[StartNowApp] Create Voucher Failure',
  receiveVouchers = '[StartNowApp] Receive Vouchers',
  receiveVouchersSuccess = '[StartNowApp] Receive Vouchers Success',
  receiveVouchersFailure = '[StartNowApp] Receive Vouchers Failure',
  resetReceiveVoucher = '[StartNowApp] Reset Receive Voucher',
  resetVoucherInfo = '[StartNowApp] Reset voucher info',
  resetPaymentInfo = '[StartNowApp] Reset payment info',
  logCardAttempt = '[StartNowApp] Log card attempt',
  logCardAttemptSuccess = '[StartNowApp] Log card attempt success',
  logCardAttemptFailure = '[StartNowApp] Log card attempt failure',
  createPassword = '[StartNowApp] Store password',
  updateAreTermsConditionsAgreed = '[StartNowApp] Update terms and conditions agreement',
  fetchConsultantAgreementPdf = '[StartNowApp] Fetch consultant agreement PDF',
  fetchConsultantAgreementPdfSuccess = '[StartNowApp] Fetch consultant agreement PDF success',
  fetchConsultantAgreementPdfFailure = '[StartNowApp] Fetch consultant agreement PDF failure',
  resetAgreementStep = '[StartNowApp] Reset agreement step',
  updateSsnNumber = '[StartNowApp] Update ssnNumber',
  updateSsnNumberSuccess = '[StartNowApp] Update ssnNumber success',
  updateSsnNumberFailure = '[StartNowApp] Update ssnNumber failure',
  updateAgreementStepNavigableFlag = '[StartNowApp] Update agreement step navigable flag',
  checkIfCanSubmitConsultant = '[StartNowApp] Check if can submit consultant',
  checkIfCanSubmitConsultantSuccess = '[StartNowApp] Check if can submit consultant success',
  checkIfCanSubmitConsultantFailure = '[StartNowApp] Check if can submit consultant failure',
  resetCanSubmitConsultantInfo = '[StartNowApp] Reset can submit consultant info',
  submitConsultant = '[StartNowApp] Submit consultant',
  submitConsultantSuccess = '[StartNowApp] Submit consultant success',
  submitConsultantFailure = '[StartNowApp] Submit consultant failure',
  resetSubmitConsultantInfo = '[StartNowApp] Reset submit consultant info',
  updateConsultantStepsSkipped = '[StartNowApp] Update consultant steps skipped',
  updateCongratulationStepMode = '[StartNowApp] Update congratulation step mode',
  fetchVoucherBarCodeUrls = '[StartNowApp] Fetch voucher barcode urls',
  fetchVoucherBarCodeUrlsSuccess = '[StartNowApp] Fetch voucher barcode urls success',
  fetchVoucherBarCodeUrlsFailure = '[StartNowApp] Fetch voucher barcode urls failure',
  updateConfirmRecruiter = '[StartNowApp] Update confirm recruiter',
  fetchReducedDepositPaymentValue = '[StartNowApp] Fetch reduced deposit payment value',
  fetchReducedDepositPaymentValueSuccess = '[StartNowApp] Fetch reduced deposit pmnt value success',
  fetchReducedDepositPaymentValueFailure = '[StartNowApp] Fetch reduced deposit pmnt value failure',
  updateIsReducedDepositPayment = '[StartNowApp] Update is Reduced DepositPayment',
  fetchIsReducedDepositAvailable = '[StartNowApp] Fetch is reduced deposit available',
  fetchIsReducedDepositAvailableSuccess = '[StartNowApp] Fetch is reduced deposit avalble success',
  fetchIsReducedDepositAvailableFailure = '[StartNowApp] Fetch is reduced deposit avalble failure',
  updateIsReducedDepositAvailablePayment = '[StartNowApp] Update is reduced deposit available',
  fetchIsZeroDepositAvailable = '[StartNowApp] Fetch is reduced zero available',
  fetchIsZeroDepositAvailableSuccess = '[StartNowApp] Fetch is zero deposit available success',
  fetchIsZeroDepositAvailableFailure = '[StartNowApp] Fetch is zero deposit available failure',
  updateIsZeroDepositPayment = '[StartNowApp] Update is zero DepositPayment',
  updatePaymentStepSkipped = '[StartNowApp] Update payment step skipped',
  setIsFinalizeButtonEnable = '[StartNowApp] Set isFinalizeButtonEnable',
  resetIsFinalizeButtonEnable = '[StartNowApp] Reset isFinalizeButtonEnable',
  resetExternalPaymentInfo = '[StartNowApp] Reset externalPaymentInfo',
}

/**
 * ReCaptcha validation
 */
export const validateReCaptcha = createAction(
  StartNowAppActionTypes.validateReCaptcha,
  props<{ token: string }>(),
);
export const validateReCaptchaSuccess = createAction(
  StartNowAppActionTypes.validateReCaptchaSuccess,
  props<{ isValid: boolean }>(),
);
export const validateReCaptchaFailure = createAction(
  StartNowAppActionTypes.validateReCaptchaFailure,
);
export const resetReCaptchaValidation = createAction(
  StartNowAppActionTypes.resetReCaptchaValidation,
);

/**
 * Step processing
 */
export const stepProcessing = createAction(
  StartNowAppActionTypes.stepProcessing,
  props<{ stepProcessing: boolean }>(),
);

/**
 * Initial steps skipped
 */
export const updateConsultantStepsSkipped = createAction(
  StartNowAppActionTypes.updateConsultantStepsSkipped,
  props<{ consultantStepsSkipped: boolean }>(),
);

/**
 * Payment step skiped when there is no payment
 */
export const updatePaymentStepSkipped = createAction(
  StartNowAppActionTypes.updatePaymentStepSkipped,
  props<{ paymentStepSkipped: boolean }>(),
);

/**
 * Find consultant by area
 */
export const findConsultantByArea = createAction(
  StartNowAppActionTypes.findConsultantByArea,
  props<{ consultantFinder: FindConsultantByAreaRequest }>(),
);

/**
 * Find consultant by area next page
 */
export const findConsultantByAreaNextPage = createAction(
  StartNowAppActionTypes.findConsultantByAreaNextPage,
  props<{ pageNumber: number; pageSize: number }>(),
);

export const findConsultantByZipCodeNextPage = createAction(
  StartNowAppActionTypes.findConsultantByZipCodeNextPage,
  props<{ pageNumber: number; pageSize: number }>(),
);

/**
 * Find consultant by code
 */
export const findConsultantByConsultantCode = createAction(
  StartNowAppActionTypes.findConsultantByConsultantCode,
  props<{ request: FindConsultantByCodeRequest }>(),
);

/**
 * Result of finders
 */
export const findConsultantSuccess = createAction(
  StartNowAppActionTypes.findConsultantSuccess,
  props<{ hasMore: boolean; items: Consultant[] }>(),
);
export const findConsultantFailure = createAction(StartNowAppActionTypes.findConsultantFailure);

/**
 * Find consultant by zip code
 */
export const findConsultantByZipCode = createAction(
  StartNowAppActionTypes.findConsultantByZipCode,
  props<{ request: FindConsultantByZipCodeRequest }>(),
);
export const findConsultantByZipCodeSuccess = createAction(
  StartNowAppActionTypes.findConsultantByZipCodeSuccess,
  props<{ res: FoundConsultantsByZipCode }>(),
);
export const findConsultantByZipCodeFailure = createAction(
  StartNowAppActionTypes.findConsultantByZipCodeFailure,
  props<{ error: any }>(),
);
export const resetFindConsultantByZipCodeErrorType = createAction(
  StartNowAppActionTypes.resetFindConsultantByZipCodeErrorType,
);

/**
 * Validate registration code
 */
export const validateRegistrationCode = createAction(
  StartNowAppActionTypes.validateRegistrationCode,
  props<{ validateRegistrationCode: ValidateRegistrationCodeRequest }>(),
);
export const validateRegistrationCodeSuccess = createAction(
  StartNowAppActionTypes.validateRegistrationCodeSuccess,
  props<{ res: ValidateRegistrationCodeResponse }>(),
);
export const validateRegistrationCodeFailure = createAction(
  StartNowAppActionTypes.validateRegistrationCodeFailure,
);

/**
 * Update registration code
 */
export const updateRegistrationCode = createAction(
  StartNowAppActionTypes.updateRegistrationCode,
  props<{ registrationCode: string }>(),
);

/**
 * Update registration code step skip
 */
export const updateRegistrationCodeStepSkipped = createAction(
  StartNowAppActionTypes.updateRegistrationCodeStepSkipped,
  props<{ skipRegistrationCodeStep: boolean; registrationCode: string }>(),
);

/**
 * Reset registration code step skip
 */
export const resetRegistrationCodeStepSkipped = createAction(
  StartNowAppActionTypes.resetRegistrationCodeStepSkipped,
);

/**
 * Reset registration code validity
 */
export const resetRegistrationCodeValidity = createAction(
  StartNowAppActionTypes.resetRegistrationCodeValidity,
);
/**
 * Reset registration code
 */
export const resetRegistrationCode = createAction(StartNowAppActionTypes.resetRegistrationCode);

/**
 * Reset address validation step
 */
export const resetAddressValidationStep = createAction(
  StartNowAppActionTypes.resetAddressValidationStep,
);

/**
 * Reset find consultant
 */
export const resetFindConsultant = createAction(StartNowAppActionTypes.resetFindConsultant);

/**
 * Reset no find consultant modal
 */
export const resetNoFindConsultantModal = createAction(
  StartNowAppActionTypes.resetNoFindConsultantModal,
);

/**
 * Reset first consultants
 */
export const resetFirstConsultants = createAction(StartNowAppActionTypes.resetFirstConsultants);

/**
 * Update selected consultant
 */
export const updateSelectedConsultant = createAction(
  StartNowAppActionTypes.updateSelectedConsultant,
  props<{ selectedConsultant: Consultant }>(),
);
export const resetSelectedConsultant = createAction(StartNowAppActionTypes.resetSelectedConsultant);

/**
 * Update StartNowAppData
 */
export const updateStartNowAppUserInfo = createAction(
  StartNowAppActionTypes.updateStartNowAppUserInfo,
  props<{ userInfo: UserInfo }>(),
);

export const updateStartNowAppUserInfoSuccess = createAction(
  StartNowAppActionTypes.updateStartNowAppUserInfoSuccess,
  props<{ saveUserInfoResult: SaveUserInfoResult }>(),
);

export const updateStartNowAppUserInfoFailure = createAction(
  StartNowAppActionTypes.updateStartNowAppUserInfoFailure,
  props<{ error: any }>(),
);

export const updateStartNowAppContactInfo = createAction(
  StartNowAppActionTypes.updateStartNowAppContactInfo,
  props<{ contactInfo: ContactInfo }>(),
);

export const updateStartNowAppContactInfoSuccess = createAction(
  StartNowAppActionTypes.updateStartNowAppContactInfoSuccess,
  props<{ saveConsultantDataResult: SaveConsultantDataResult }>(),
);

export const updateStartNowAppContactInfoFailure = createAction(
  StartNowAppActionTypes.updateStartNowAppContactInfoFailure,
  props<{ error: any }>(),
);

export const updateStartNowAppAddressInfo = createAction(
  StartNowAppActionTypes.updateStartNowAppAddressInfo,
  props<{ addresses: Address[]; languagePreferred: string }>(),
);

export const updateStartNowAppAddressInfoSuccess = createAction(
  StartNowAppActionTypes.updateStartNowAppAddressInfoSuccess,
  props<{ saveConsultantDataResult: SaveConsultantDataResult }>(),
);

export const updateStartNowAppAddressInfoFailure = createAction(
  StartNowAppActionTypes.updateStartNowAppAddressInfoFailure,
  props<{ error: any }>(),
);

export const updateAddressValidationModalValidity = createAction(
  StartNowAppActionTypes.updateAddressValidationModalValidity,
  props<{ isSuccess: boolean }>(),
);

export const addAddress = createAction(
  StartNowAppActionTypes.addAddress,
  props<{ address: Address }>(),
);

export const updateAreTermsConditionsAgreed = createAction(
  StartNowAppActionTypes.updateAreTermsConditionsAgreed,
  props<{ areTermsConditionsAgreed: boolean }>(),
);

/** Reset steps */
export const resetAboutYouStep = createAction(StartNowAppActionTypes.resetAboutYouStep);

export const resetContactStep = createAction(StartNowAppActionTypes.resetContactStep);

/**
 * Reset agreement step
 */
export const resetAgreementStep = createAction(StartNowAppActionTypes.resetAgreementStep);

/**
 * Update ssnNumber
 */
export const updateSsnNumber = createAction(
  StartNowAppActionTypes.updateSsnNumber,
  props<{ ssnNumber: string }>(),
);
export const updateSsnNumberSuccess = createAction(
  StartNowAppActionTypes.updateSsnNumberSuccess,
  props<{ saveConsultantDataResult: SaveConsultantDataResult }>(),
);
export const updateSsnNumberFailure = createAction(
  StartNowAppActionTypes.updateSsnNumberFailure,
  props<{ error: any }>(),
);

/**
 * Update agreement step navigable flag
 */
export const updateAgreementStepNavigableFlag = createAction(
  StartNowAppActionTypes.updateAgreementStepNavigableFlag,
  props<{ isNextEnabled: boolean }>(),
);

/**
 * Create StartNowAppData
 */
export const createPassword = createAction(
  StartNowAppActionTypes.createPassword,
  props<{ password: string }>(),
);

/**
 * Validate Address
 */
export const validateAddress = createAction(
  StartNowAppActionTypes.validateAddress,
  props<{ address: Address }>(),
);

export const validateAddressSuccess = createAction(
  StartNowAppActionTypes.validateAddressSuccess,
  props<{ addressValidation: VerifyAddressResponse }>(),
);

export const validateAddressFailure = createAction(
  StartNowAppActionTypes.validateAddressFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetAddressValidationModal = createAction(
  StartNowAppActionTypes.resetAddressValidationModal,
);

/**
 * Starter Kit
 */
export const fetchStartNowAppStarterKits = createAction(
  StartNowAppActionTypes.fetchStartNowAppStarterKits,
  props<{ starterKitLanguageCode: LanguagePreferenceType; isNonPfas: boolean }>(),
);

export const fetchStartNowAppStarterKitsSuccess = createAction(
  StartNowAppActionTypes.fetchStartNowAppStarterKitsSuccess,
  props<{ starterKits: StarterKitDto[] }>(),
);
export const fetchStartNowAppStarterKitsFailure = createAction(
  StartNowAppActionTypes.fetchStartNowAppStarterKitsFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Update selected starter kit
 */

export const updateStartNowAppSelectedStarterKit = createAction(
  StartNowAppActionTypes.updateStartNowAppSelectedStarterKit,
  props<{
    selectedStarterKit: StarterKitDto;
    selectedStartNowPaymentType: StartNowPaymentType;
    isDepositPayment: boolean;
    isReducedDepositPayment: boolean;
    isZeroDepositPayment: boolean;
  }>(),
);
export const updateStartNowAppSelectedStarterKitSuccess = createAction(
  StartNowAppActionTypes.updateStartNowAppSelectedStarterKitSuccess,
  props<{ saveConsultantDataResult: SaveConsultantDataResult }>(),
);
export const updateStartNowAppSelectedStarterKitFailure = createAction(
  StartNowAppActionTypes.updateStartNowAppSelectedStarterKitFailure,
  props<{ error: any }>(),
);

/**
 * Fetch order summary
 */
export const fetchOrderSummary = createAction(StartNowAppActionTypes.fetchOrderSummary);
export const fetchOrderSummarySuccess = createAction(
  StartNowAppActionTypes.fetchOrderSummarySuccess,
  props<{ res: OrderSummaryResponse }>(),
);
export const fetchOrderSummaryFailure = createAction(
  StartNowAppActionTypes.fetchOrderSummaryFailure,
);

/**
 * Reset order summary
 */
export const resetOrderSummary = createAction(StartNowAppActionTypes.resetOrderSummary);

/**
 * Fetch deposit payment value
 */
export const fetchDepositPaymentValue = createAction(
  StartNowAppActionTypes.fetchDepositPaymentValue,
);
export const fetchDepositPaymentValueSuccess = createAction(
  StartNowAppActionTypes.fetchDepositPaymentValueSuccess,
  props<{ res: number }>(),
);
export const fetchDepositPaymentValueFailure = createAction(
  StartNowAppActionTypes.fetchDepositPaymentValueFailure,
);

/**
 * Update isDepositPayment
 */
export const updateIsDepositPayment = createAction(
  StartNowAppActionTypes.updateIsDepositPayment,
  props<{ isDepositPayment: boolean }>(),
);

/**
 * Fetch reduced deposit payment value
 */
export const fetchReducedDepositPaymentValue = createAction(
  StartNowAppActionTypes.fetchReducedDepositPaymentValue,
);
export const fetchReducedDepositPaymentValueSuccess = createAction(
  StartNowAppActionTypes.fetchReducedDepositPaymentValueSuccess,
  props<{ res: number }>(),
);
export const fetchReducedDepositPaymentValueFailure = createAction(
  StartNowAppActionTypes.fetchReducedDepositPaymentValueFailure,
);

/**
 * Update isReducedDepositPayment
 */
export const updateIsReducedDepositPayment = createAction(
  StartNowAppActionTypes.updateIsReducedDepositPayment,
  props<{ isReducedDepositPayment: boolean }>(),
);

/**
 * Update updateIsZeroDepositPayment
 */
export const updateIsZeroDepositPayment = createAction(
  StartNowAppActionTypes.updateIsZeroDepositPayment,
  props<{ isZeroDepositPayment: boolean }>(),
);

/**
 * Fetch reduced deposit payment value
 */
export const fetchIsReducedDepositAvailable = createAction(
  StartNowAppActionTypes.fetchIsReducedDepositAvailable,
);
export const fetchIsReducedDepositAvailableSuccess = createAction(
  StartNowAppActionTypes.fetchIsReducedDepositAvailableSuccess,
  props<{ res: boolean }>(),
);
export const fetchIsReducedDepositAvailableFailure = createAction(
  StartNowAppActionTypes.fetchIsReducedDepositAvailableFailure,
);

/**
 * Fetch is zero deposit value
 */
export const fetchIsZeroDepositAvailable = createAction(
  StartNowAppActionTypes.fetchIsZeroDepositAvailable,
);
export const fetchIsZeroDepositAvailableSuccess = createAction(
  StartNowAppActionTypes.fetchIsZeroDepositAvailableSuccess,
  props<{ res: boolean }>(),
);
export const fetchIsZeroDepositAvailableFailure = createAction(
  StartNowAppActionTypes.fetchIsZeroDepositAvailableFailure,
);

/**
 * Check if new payment required based on starter kit changes based on back/forth UI navigation
 */
export const checkIfNewPaymentRequired = createAction(
  StartNowAppActionTypes.checkIfNewPaymentRequired,
  props<{
    applicationRegistrationId: number;
    starterKitId: number;
    isDepositPayment: boolean;
    isReducedDepositPayment: boolean;
    isZeroDepositPayment: boolean;
  }>(),
);
export const checkIfNewPaymentRequiredSuccess = createAction(
  StartNowAppActionTypes.checkIfNewPaymentRequiredSuccess,
  props<{ res: boolean }>(),
);
export const checkIfNewPaymentRequiredFailure = createAction(
  StartNowAppActionTypes.checkIfNewPaymentRequiredFailure,
  props<{ error: any }>(),
);

/**
 * Log card attempt
 */
export const logCardAttempt = createAction(
  StartNowAppActionTypes.logCardAttempt,
  props<{ request: SaveLogCardAttemptRequest }>(),
);

export const logCardAttemptSuccess = createAction(StartNowAppActionTypes.logCardAttemptSuccess);

export const logCardAttemptFailure = createAction(
  StartNowAppActionTypes.logCardAttemptFailure,
  props<{ error: any }>(),
);

/**
 * Reset starter kit step
 */
export const resetStarterKitStep = createAction(StartNowAppActionTypes.resetStarterKitStep);

/**
 * Fetch Country States
 */
export const fetchCountryStates = createAction(StartNowAppActionTypes.fetchCountryStates);
export const fetchCountryStatesSuccess = createAction(
  StartNowAppActionTypes.fetchCountryStatesSuccess,
  props<{ countryStates: CountryState[] }>(),
);

export const fetchCountryStatesFailure = createAction(
  StartNowAppActionTypes.fetchCountryStatesFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Check email uniqueness
 */
export const emailUniquenessCheck = createAction(
  StartNowAppActionTypes.emailUniquenessCheck,
  props<{ email: string }>(),
);
export const emailUniquenessCheckSuccess = createAction(
  StartNowAppActionTypes.emailUniquenessCheckSuccess,
  props<{ emailIsValid: boolean }>(),
);
export const emailUniquenessCheckFailure = createAction(
  StartNowAppActionTypes.emailUniquenessCheckFailure,
  props<{ error: any }>(),
);
export const resetEmailValidity = createAction(StartNowAppActionTypes.resetEmailValidity);

/**
 * Check phone number uniqueness
 */
export const phoneNumberUniquenessCheck = createAction(
  StartNowAppActionTypes.phoneNumberUniquenessCheck,
  props<{ request: PhoneNumberValidationRequest }>(),
);
export const phoneNumberUniquenessCheckSuccess = createAction(
  StartNowAppActionTypes.phoneNumberUniquenessCheckSuccess,
  props<{ response: PhoneNumberValidationResponse }>(),
);
export const phoneNumberUniquenessCheckFailure = createAction(
  StartNowAppActionTypes.phoneNumberUniquenessCheckFailure,
  props<{ error: any }>(),
);
export const resetPhoneNumberValidity = createAction(
  StartNowAppActionTypes.resetPhoneNumberValidity,
  props<{ phoneNumberType: PhoneNumberType }>(),
);

/**
 * Fetch client token
 **/
export const fetchClientToken = createAction(
  StartNowAppActionTypes.fetchClientToken,
  props<{ payload: ClientTokenRequest }>(),
);
export const fetchClientTokenSuccess = createAction(
  StartNowAppActionTypes.fetchClientTokenSuccess,
  props<{ payload: ClientTokenResponse }>(),
);
export const fetchClientTokenFailure = createAction(
  StartNowAppActionTypes.fetchClientTokenFailure,
  props<{ error: PhErrorResponse }>(),
);
export const resetClientToken = createAction(StartNowAppActionTypes.resetClientToken);

/**
 * Store payment info
 **/
export const storePaymentType = createAction(
  StartNowAppActionTypes.storePaymentType,
  props<{ paymentType: PaymentType }>(),
);
export const storeCardPaymentInfo = createAction(
  StartNowAppActionTypes.storeCardPaymentInfo,
  props<{ payload: CardPaymentInfo }>(),
);
export const storeVoucherPaymentInfo = createAction(
  StartNowAppActionTypes.storeVoucherPaymentInfo,
  props<{ payload: VoucherPaymentInfo }>(),
);
export const storeExternalPaymentInfo = createAction(
  StartNowAppActionTypes.storeExternalPaymentInfo,
  props<{ paymentInfo: PaymentInfo }>(),
);
export const storeExternalPaymentInfoSuccess = createAction(
  StartNowAppActionTypes.storeExternalPaymentInfoSuccess,
  props<{ payload: PaymentInfo }>(),
);
export const storeExternalPaymentInfoFailure = createAction(
  StartNowAppActionTypes.storeExternalPaymentInfoFailure,
  props<{ error: any }>(),
);

/**
 * Update payment provider type
 */
export const updatePaymentProviderType = createAction(
  StartNowAppActionTypes.updatePaymentProviderType,
  props<{ provider: PaymentProviderType }>(),
);

/**
 * Create Order
 **/
export const createOrder = createAction(
  StartNowAppActionTypes.createOrder,
  props<{ payload: CreateOrderRequest }>(),
);
export const createOrderSuccess = createAction(
  StartNowAppActionTypes.createOrderSuccess,
  props<{ payload: CreateOrderResponse }>(),
);
export const createOrderFailure = createAction(
  StartNowAppActionTypes.createOrderFailure,
  props<{ error: any }>(),
);

/**
 * Update Order
 */
export const updateOrder = createAction(
  StartNowAppActionTypes.updateOrder,
  props<{ payload: UpdateOrderRequest }>(),
);
export const updateOrderSuccess = createAction(
  StartNowAppActionTypes.updateOrderSuccess,
  props<{ response: UpdateOrderResponse }>(),
);
export const updateOrderFailure = createAction(
  StartNowAppActionTypes.updateOrderFailure,
  props<{ error: any }>(),
);

/**
 * Create Payment Method
 **/
export const createPaymentMethod = createAction(
  StartNowAppActionTypes.createPaymentMethod,
  props<{ payload: CreatePaymentMethodRequest }>(),
);
export const createPaymentMethodSuccess = createAction(
  StartNowAppActionTypes.createPaymentMethodSuccess,
  props<{ payload: CreatePaymentMethodResponse }>(),
);
export const createPaymentMethodFailure = createAction(
  StartNowAppActionTypes.createPaymentMethodFailure,
  props<{ error: any }>(),
);

export const resetPaymentMethod = createAction(StartNowAppActionTypes.resetPaymentMethod);

/**
 * Update Additional Billing Information
 */
export const updateAdditionalBillingInformation = createAction(
  StartNowAppActionTypes.updateAdditionalBillingInformation,
  props<{ additionalBillingInformation: AdditionalBillingInformation }>(),
);

/**
 * Reset Additional Billing Information
 */
export const resetAdditionalBillingInformation = createAction(
  StartNowAppActionTypes.resetAdditionalBillingInformation,
);

/**
 * Create, Receive Voucher
 **/
export const createVoucher = createAction(
  StartNowAppActionTypes.createVoucher,
  props<{ payload: CreateVoucherRequest }>(),
);
export const createVoucherSuccess = createAction(
  StartNowAppActionTypes.createVoucherSuccess,
  props<{ payload: CreatedVoucherPaymentMethod }>(),
);
export const createVoucherFailure = createAction(
  StartNowAppActionTypes.createVoucherFailure,
  props<{ error: any }>(),
);
export const receiveVouchers = createAction(
  StartNowAppActionTypes.receiveVouchers,
  props<{ vouchers: ReceiveVouchersRequest }>(),
);
export const receiveVouchersSuccess = createAction(
  StartNowAppActionTypes.receiveVouchersSuccess,
  props<{ payload: ReceiveVouchersResponse }>(),
);
export const receiveVouchersFailure = createAction(
  StartNowAppActionTypes.receiveVouchersFailure,
  props<{ error: any }>(),
);
export const resetReceiveVoucher = createAction(StartNowAppActionTypes.resetReceiveVoucher);

/**
 * Reset voucher info
 */
export const resetVoucherInfo = createAction(StartNowAppActionTypes.resetVoucherInfo);

/**
 * Reset payment info
 */
export const resetPaymentInfo = createAction(StartNowAppActionTypes.resetPaymentInfo);

/**
 * Check can submit consultant
 **/
export const checkIfCanSubmitConsultant = createAction(
  StartNowAppActionTypes.checkIfCanSubmitConsultant,
  props<{ payload: SubmitConsultantRequest }>(),
);

export const checkIfCanSubmitConsultantSuccess = createAction(
  StartNowAppActionTypes.checkIfCanSubmitConsultantSuccess,
  props<{ payload: SubmitConsultantResponse }>(),
);

export const checkIfCanSubmitConsultantFailure = createAction(
  StartNowAppActionTypes.checkIfCanSubmitConsultantFailure,
  props<{ error: any }>(),
);

export const resetCanSubmitConsultantInfo = createAction(
  StartNowAppActionTypes.resetCanSubmitConsultantInfo,
);

/**
 * Submit consultant
 **/
export const submitConsultant = createAction(
  StartNowAppActionTypes.submitConsultant,
  props<{ payload: SubmitConsultantRequest }>(),
);

export const submitConsultantSuccess = createAction(
  StartNowAppActionTypes.submitConsultantSuccess,
  props<{ payload: SubmitConsultantResponse }>(),
);

export const submitConsultantFailure = createAction(
  StartNowAppActionTypes.submitConsultantFailure,
  props<{ error: any }>(),
);

export const resetSubmitConsultantInfo = createAction(
  StartNowAppActionTypes.resetSubmitConsultantInfo,
);

/**
 * Update congratulation step mode
 */
export const updateCongratulationStepMode = createAction(
  StartNowAppActionTypes.updateCongratulationStepMode,
  props<{ mode: CongratulationType }>(),
);

/**
 * Fetch voucher barcode urls
 */
export const fetchVoucherBarCodeUrls = createAction(
  StartNowAppActionTypes.fetchVoucherBarCodeUrls,
  props<{ applicationRegistrationId: number }>(),
);
export const fetchVoucherBarCodeUrlsSuccess = createAction(
  StartNowAppActionTypes.fetchVoucherBarCodeUrlsSuccess,
  props<{ urls: string[] }>(),
);
export const fetchVoucherBarCodeUrlsFailure = createAction(
  StartNowAppActionTypes.fetchVoucherBarCodeUrlsFailure,
);

/**
 * Update confirm recruiter
 */
export const updateConfirmRecruiter = createAction(
  StartNowAppActionTypes.updateConfirmRecruiter,
  props<{ confirmRecruiter: boolean }>(),
);

/**
 * Set isFinalizeButtonEnable
 */
export const setIsFinalizeButtonEnable = createAction(
  StartNowAppActionTypes.setIsFinalizeButtonEnable,
  props<{ isFinalizeButtonEnable: boolean }>(),
);

/**
 * reset isFinalizeButtonEnable
 */
export const resetIsFinalizeButtonEnable = createAction(
  StartNowAppActionTypes.resetIsFinalizeButtonEnable,
);

/**
 * We need to reset externalPaymentInfo case of kushki because
 * we need to listen the nonce for check kushki payment is saved
 */
export const resetExternalPaymentInfo = createAction(
  StartNowAppActionTypes.resetExternalPaymentInfo,
);
