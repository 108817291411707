import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-saved-bank-transfer',
  templateUrl: './saved-bank-transfer.component.html',
  styleUrls: ['./saved-bank-transfer.component.scss'],
})
export class SavedBankTransferComponent {
  @Input() canChangeSavedBankTransfer: boolean = true;
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  constructor() {}

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
