/* eslint-disable max-len */
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';

export const ConsultantSubmitErrorMessages: ConsultantSubmitErrorMessageType = {
  [ConsultantSubmissionErrorType.ApplicationRegistrationNotSubmittable]: $localize`Application registration not submittable`,
  [ConsultantSubmissionErrorType.ContractReleaseFailed]: $localize`Contract release failed`,
  [ConsultantSubmissionErrorType.ContractPaidByVoucher]: $localize`Contract is paid by Voucher`,
  [ConsultantSubmissionErrorType.ContractPaidByBankTransfer]: $localize`Contract is paid by Bank transfer`,
  [ConsultantSubmissionErrorType.ConsultantProwessIdCouldNotRetrieved]: $localize`Consultant ProwessId could not be retrieved`,
  [ConsultantSubmissionErrorType.ConsultantProwessIdCouldNotSaved]: $localize`Consultant ProwessId could not be saved`,
  [ConsultantSubmissionErrorType.DuplicateHomePhone]: $localize`Duplicate home phone number`,
  [ConsultantSubmissionErrorType.DuplicateMobilePhone]: $localize`Duplicate mobile phone number`,
  [ConsultantSubmissionErrorType.DuplicateProwessId]: $localize`Duplicate ProwessId`,
  [ConsultantSubmissionErrorType.EmailAlreadyExists]: $localize`This e-mail address is already registered on one of our Consultants. Please change
  your e-mail address!`,
  [ConsultantSubmissionErrorType.ErrorWhileSync]: $localize`Error while Sync`,
  [ConsultantSubmissionErrorType.InvalidAddress]: $localize`Invalid address`,
  [ConsultantSubmissionErrorType.InvalidBirthDate]: $localize`Invalid birth date`,
  [ConsultantSubmissionErrorType.InvalidConsultantLevel]: $localize`Invalid consultant level`,
  [ConsultantSubmissionErrorType.InvalidConsultantStatus]: $localize`Invalid consultant status`,
  [ConsultantSubmissionErrorType.InvalidEmailFormat]: $localize`Invalid email format`,
  [ConsultantSubmissionErrorType.InvalidHomePhone]: $localize`Invalid home phone number`,
  [ConsultantSubmissionErrorType.InvalidMobilePhone]: $localize`Invalid mobile phone number`,
  [ConsultantSubmissionErrorType.InvalidPaymentType]: $localize`Invalid payment type`,
  [ConsultantSubmissionErrorType.InvalidRecruiterId]:
    'Consultant is invalid due to some data corruption! User from SNA is not synced from UserManagement',
  [ConsultantSubmissionErrorType.InvalidState]: $localize`Invalid state`,
  [ConsultantSubmissionErrorType.InvalidStarterKitId]: $localize`Invalid starter kit`,
  [ConsultantSubmissionErrorType.InvalidStarterKitPrice]: $localize`Invalid starter kit price`,
  [ConsultantSubmissionErrorType.MissingAddressLine1]: $localize`Missing address line 1`,
  [ConsultantSubmissionErrorType.MissingCity]: $localize`Missing city`,
  [ConsultantSubmissionErrorType.MissingContactInfo]: $localize`Missing contact info`,
  [ConsultantSubmissionErrorType.MissingAddressInfo]: $localize`Missing address info`,
  [ConsultantSubmissionErrorType.MissingB2CId]: $localize`Missing B2C Id`,
  [ConsultantSubmissionErrorType.MissingEmail]: $localize`Missing email`,
  [ConsultantSubmissionErrorType.MissingFirstName]: $localize`Missing first name`,
  [ConsultantSubmissionErrorType.MissingHomeAddress]: $localize`Missing home address`,
  [ConsultantSubmissionErrorType.MissingLastName]: $localize`Missing last name`,
  [ConsultantSubmissionErrorType.MissingLevel]: $localize`Missing level`,
  [ConsultantSubmissionErrorType.MissingPaymentMethod]: $localize`Missing Payment Method`,
  [ConsultantSubmissionErrorType.MissingPaymentMethodToken]: $localize`Missing Payment Method Token`,
  [ConsultantSubmissionErrorType.MissingRecruiter]: $localize`Missing recruiter/consultant`,
  [ConsultantSubmissionErrorType.MissingRegistrationId]: $localize`Missing RegistrationId`,
  [ConsultantSubmissionErrorType.InvalidSsnNumber]: $localize`Invalid social security number`,
  [ConsultantSubmissionErrorType.InvalidZip]: $localize`Invalid zip code`,
  [ConsultantSubmissionErrorType.MissingState]: $localize`Missing state`,
  [ConsultantSubmissionErrorType.MissingStatus]: $localize`Missing status`,
  [ConsultantSubmissionErrorType.MissingUserInfo]: $localize`Missing user info`,
  [ConsultantSubmissionErrorType.MissingProwessId]: $localize`Missing ProwessId`,
  [ConsultantSubmissionErrorType.MissingVanityName]: $localize`Missing vanity name`,
  [ConsultantSubmissionErrorType.MissingZip]: $localize`Missing zip`,
  [ConsultantSubmissionErrorType.ProwessImportFailed]: $localize`Prowess import is failed`,
  [ConsultantSubmissionErrorType.TooLongAddressLine1]: $localize`Too long address line 1`,
  [ConsultantSubmissionErrorType.TooLongAddressLine2]: $localize`Too long address line 2`,
  [ConsultantSubmissionErrorType.TooLongAddressLine3]: $localize`Too long address line 3`,
  [ConsultantSubmissionErrorType.TooLongCity]: $localize`Too long city`,
  [ConsultantSubmissionErrorType.TooLongFirstName]: $localize`Too long first name`,
  [ConsultantSubmissionErrorType.TooLongLastName]: $localize`Too long last name`,
  [ConsultantSubmissionErrorType.TooLongMiddleName]: $localize`Too long middle name`,
  [ConsultantSubmissionErrorType.TooLongHomeNumber]: $localize`Too long home number`,
  [ConsultantSubmissionErrorType.TooLongMobileNumber]: $localize`Too long mobile number`,
  [ConsultantSubmissionErrorType.TooLongEmail]: $localize`Too long email`,
  [ConsultantSubmissionErrorType.TooLongVanityName]: $localize`Too long vanity name`,
  [ConsultantSubmissionErrorType.TooLongZip]: $localize`Too long zip`,
  [ConsultantSubmissionErrorType.TransactionFailed]: $localize`Please add another card and try again!`,
  [ConsultantSubmissionErrorType.UnsupportedLanguage]: $localize`Unsupported language`,
  [ConsultantSubmissionErrorType.UserManagementInAccessible]: $localize`UserManagement is inaccessible`,
  [ConsultantSubmissionErrorType.VaultImportFailed]: $localize`A temporary issue occurred, please try to submit the application again later`,
  [ConsultantSubmissionErrorType.MissingVoucherDetails]: $localize`Sorry, but the system couldn’t generate your voucher! Please try again!`,
  [ConsultantSubmissionErrorType.InvalidRegistrationCode]: $localize`Invalid registration code`,
  [ConsultantSubmissionErrorType.DailyVoucherAmountReached]: $localize`Daily voucher limit reached, the amount should be less than or equal to`,
  [ConsultantSubmissionErrorType.MonthlyVoucherAmountReached]: $localize`Monthly voucher limit reached, the amount should be less than or equal to`,
  [ConsultantSubmissionErrorType.YearlyVoucherAmountReached]: $localize`Yearly voucher limit reached, the amount should be less than or equal to`,
  [ConsultantSubmissionErrorType.Other]: $localize`Unknown other error`,
};

export type ConsultantSubmitErrorMessageType = { [key in ConsultantSubmissionErrorType]?: string };
