import { PaymentCardType } from '@core/enums/payment-type.enum';

export const PaymentImageSrc = {
  visaCard: 'assets/images/icons/payment_visa.png',
  masterCard: 'assets/images/icons/payment_mastercard.png',
  payByCashEleven: 'assets/images/icons/payment_eleven.png',
  payByCashWalmart: 'assets/images/icons/payment_walmart.png',
  payByCashWaldos: 'assets/images/icons/payment_waldos.png',
  payByCashAustriano: 'assets/images/icons/payment_asturiano.png',
  payByCashBodegaAurrera: 'assets/images/icons/payment_bodegaAurrera.png',
  payByCashCircleK: 'assets/images/icons/payment_circleK.jpg',
  payByCashFarmaciasDelAhorro: 'assets/images/icons/payment_farmaciasDelAhorro.png',
  payByCashSamsClub: 'assets/images/icons/payment_samsClub.png',
  payByCashExtra: 'assets/images/icons/payment_extra.png',
  voucherBarcode: 'assets/images/icons/payment-voucher-barcode.svg',
  voucherFile: 'assets/images/icons/payment-voucher-file.svg',
  voucherShop: 'assets/images/icons/payment-voucher-shop.svg',
  oxxo: 'assets/images/icons/oxxo.png',
  paypal: 'assets/images/icons/paypal.png',
  spei: 'assets/images/icons/spei-logo.svg',
};

export const CardPaymentTypes: Record<PaymentCardType, string> = {
  [PaymentCardType.DebitCard]: $localize`Debit card`,
  [PaymentCardType.CreditCard]: $localize`Credit card`,
  [PaymentCardType.BankTransfer]: $localize`Bank transfer`,
};
