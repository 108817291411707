import * as snaErrorMessages from '@core/constants/sna-error-messages';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { StatusCodes } from 'http-status-codes';

export function getSnaErrorTitle(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.title;
    default:
      return snaErrorMessages.GenericErrorMessage.title;
  }
}

export function getSnaErrorMessage(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.message;
    default:
      return snaErrorMessages.GenericErrorMessage.message;
  }
}

export function getCheckCanSubmitSnaErrorTitle(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.title;
    default:
      return snaErrorMessages.CheckSnaCanSubmitGenericErrorMessage.title;
  }
}

export function getCheckCanSubmitSnaErrorMessage(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.message;
    default:
      return snaErrorMessages.CheckSnaCanSubmitGenericErrorMessage.message;
  }
}

export function getSnaSubmitErrorTitle(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.title;
    default:
      return snaErrorMessages.SnaSubmitGenericErrorMessage.title;
  }
}

export function getSnaSubmitErrorMessage(statusCode: StatusCodes): string {
  switch (statusCode) {
    case StatusCodes.SERVICE_UNAVAILABLE:
      return snaErrorMessages.SnaDisabledErrorMessage.message;
    default:
      return snaErrorMessages.SnaSubmitGenericErrorMessage.message;
  }
}

export function isAfterCardTransaction(errorTypes: ConsultantSubmissionErrorType[]): boolean {
  return (
    errorTypes.includes(ConsultantSubmissionErrorType.VaultImportFailed) ||
    errorTypes.includes(ConsultantSubmissionErrorType.ProwessImportFailed) ||
    errorTypes.includes(ConsultantSubmissionErrorType.ErrorWhileSync)
  );
}
