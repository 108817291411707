<app-full-page-spinner
  *ngIf="
    (isVouchersLoading$ | async) === true ||
    (submissionIsInProgress$ | async) === true ||
    (isStepProcessing$ | async) ||
    paymentProviderFirstLoading
  "
></app-full-page-spinner>

<div class="checkout-slide">
  <div class="row my-3 d-none d-md-flex">
    <div class="col-6 col-md-4" [formGroup]="paymentTypeForm">
      <app-select
        [identifier]="PaymentTypeSelector"
        label="Payment Type"
        i18n-label
        [formControlName]="PaymentTypeSelector"
        [options]="paymentTypeOptions"
        [readonly]="
          paymentTypeOptions.length === 1 || isPaymentProviderLoading || isPaymentApproved
        "
      ></app-select>
    </div>
    <div *ngIf="isMexEnv" class="col-6 col-md-4">
      <div class="mb-1">
        <span i18n>Accepted Payment Methods</span>
      </div>
      <div class="d-flex">
        <img
          *ngFor="let imageSrc of availablePaymentMethodsImgSrc"
          [ngClass]="{ 'spei-logo': imageSrc === PaymentImgSrc.spei }"
          class="payment-icon px-1"
          [src]="imageSrc"
        />
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="border-right col-12 col-md-6">
      <app-billing-address-form
        #billingAddress
        [paymentProviderType]="paymentProviderType"
        [isCreditOrDebitCard]="selectedPaymentType === PaymentType.CreditOrDebitCard"
        (reInitializeToken)="reInitializeToken($event)"
      ></app-billing-address-form>
    </div>

    <div class="d-flex col-12 col-md-6 flex-column">
      <div
        *ngIf="
          selectedPaymentType === PaymentType.CreditOrDebitCard ||
            selectedPaymentType === PaymentType.PayPal ||
            selectedPaymentType === PaymentType.BankTransfer;
          else voucher
        "
        [ngClass]="{
          'd-none': hasCardPaymentMethodError
        }"
      >
        <div *ngIf="isUsaEnv && isAuthenticated$ | async" i18n>
          You may select one of the credit cards on file, or enter a new one for your payment.
        </div>
        <app-card-payment-wrapper
          *ngIf="!isCardSaved"
          [class]="{
            'd-none':
              isPaymentApproved ||
              hasCardPaymentMethodError ||
              (hasOrderPaymentError$ | async) ||
              (paymentIsFailed$ | async)
          }"
          [paymentHandler]="paymentHandler"
          [paymentProviderType]="paymentProviderType"
          [isPayPalCardPaymentEnabled]="isPayPalCardPaymentEnabled"
          [kushkiFormIdType]="kushkiFormIdType"
          (isPaymentMethodRequestable)="toggleDisabled($event)"
          (isPaymentApproved)="paymentApproved($event)"
          (isPaymentProviderLoading)="handleIsPaymentProviderLoading($event)"
          [disableInput]="
            isBillingAddressFormInvalid &&
            (selectedPaymentType === PaymentType.CreditOrDebitCard ||
              selectedPaymentType === PaymentType.PayPal)
          "
          #cardPaymentWrapper
        ></app-card-payment-wrapper>
      </div>
      <app-saved-paypal-payment
        *ngIf="
          isPaymentApproved &&
          selectedPaymentType === PaymentType.PayPal &&
          !hasCardPaymentMethodError
        "
        (resetPayment)="resetCardPayment(selectedPaymentType, true)"
      ></app-saved-paypal-payment>
      <section
        *ngIf="
          (isCardSaved || isPaymentApproved) &&
          selectedPaymentType !== PaymentType.PayPal &&
          !hasCardPaymentMethodError
        "
      >
        <div *ngIf="selectedPaymentType !== PaymentType.BankTransfer; else savedBankTransfer">
          <app-saved-card
            class="d-flex w-100 mb-5"
            [cardTypeDisplayValue]="CardPaymentTypes[selectedCardPaymentType]"
            [cardInfo]="cardPaymentMethodInfo"
            (resetPayment)="resetCardPayment(selectedPaymentType)"
          >
          </app-saved-card>
        </div>
        <ng-template #savedBankTransfer>
          <app-saved-bank-transfer
            class="d-flex w-100 mb-5"
            (resetPayment)="resetCardPayment(selectedPaymentType)"
          >
          </app-saved-bank-transfer>
        </ng-template>
      </section>
      <div
        *ngIf="
          hasCardPaymentMethodError || (hasOrderPaymentError$ | async) || (paymentIsFailed$ | async)
        "
        class="mb-5"
      >
        <div *ngIf="selectedPaymentType !== PaymentType.BankTransfer; else bankTransferError">
          <app-create-card-payment-method-error
            [cardTypeDisplayValue]="CardPaymentTypes[selectedCardPaymentType]"
            [cardPaymentErrorType]="cardPaymentErrorType"
            [cardInfo]="cardPaymentMethodInfo?.card"
            (resetPayment)="resetCardPayment(selectedPaymentType, true)"
          ></app-create-card-payment-method-error>
        </div>
        <ng-template #bankTransferError>
          <app-bank-transfer-error
            (resetPayment)="resetCardPayment(selectedPaymentType, true)"
          ></app-bank-transfer-error>
        </ng-template>
      </div>
      <div *ngIf="!isCardFormValid()" class="validation-error-message">
        <span *ngIf="isMexEnv" i18n>
          Please fill in and submit the payment related fields with valid values!
        </span>
      </div>

      <app-cart-totals
        class="d-md-flex flex-row mt-2 justify-content-end"
        [cart]="cart$"
      ></app-cart-totals>
    </div>
    <ng-template #voucher>
      <app-checkout-voucher (emailChange)="onEmailChange($event)"></app-checkout-voucher>
    </ng-template>
  </div>

  <div class="d-md-none">
    <app-cart-totals [cart]="cart$"></app-cart-totals>
  </div>

  <div class="row justify-content-between">
    <div class="col col-md-5">
      <button class="btn btn-outline-dark text-uppercase w-100" (click)="back()" i18n>
        Back to Summary
      </button>
    </div>
    <div class="col col-md-5">
      <button
        class="btn btn-primary text-uppercase w-100"
        type="button"
        (click)="next()"
        [disabled]="
          disabled ||
          isBillingAddressFormInvalid ||
          hasCardPaymentMethodError ||
          (isStepProcessing$ | async) ||
          (hasOrderPaymentError$ | async) ||
          (submissionIsInProgress$ | async) ||
          isPaymentProviderLoading
        "
        i18n
        >Pay & Submit Order
      </button>
    </div>
  </div>
</div>

<app-failed-order-submit-modal
  #failedModal
  [updatedOrderItems]="updatedOrderItems$ | async"
  [deletedOrderItems]="deletedOrderItems$ | async"
>
</app-failed-order-submit-modal>
