import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bank-transfer-error',
  templateUrl: './bank-transfer-error.component.html',
  styleUrls: ['./bank-transfer-error.component.scss'],
})
export class BankTransferErrorComponent {
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  constructor() {}

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
