export enum PaymentMethodType {
  Unknown = 0,
  CreditOrDebitCard = 1,
  DebitCard = 2,
  CreditCard = 3,
  Voucher = 4,
  Account = 5,
  Finance = 6,
  BankTransfer = 7,
}
