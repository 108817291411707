import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConsultantSubmitErrorMessages } from '@core/constants/consultant-submit-error-message';
import { SubmitConsultantRequest } from '@core/dto/start-now-app.dto';
import { CongratulationType } from '@core/enums/congratulation-type.enum';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { LoggerService } from '@core/services/logger.service';
import { AppState } from '@core/store';
import {
  selectCanSubmitConsultantInfo,
  selectPaymentStepSkipped,
  selectStartNowAppData,
  selectStepProcessing,
  selectSubmitConsultantStatusCode,
  selectSubmittedConsultantInfo,
  selectVoucherPaymentInfo,
} from '@core/store/start-now-app';
import {
  SubmittedConsultantInfo,
  VoucherPaymentInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import {
  checkIfCanSubmitConsultant,
  resetCanSubmitConsultantInfo,
  resetReceiveVoucher,
  resetSubmitConsultantInfo,
  stepProcessing,
  submitConsultant,
  updateCongratulationStepMode,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { OxxoVoucherPaymentModalComponent } from '@payment/components/voucher-payment-modal/oxxo-voucher-payment-modal/oxxo-voucher-payment-modal.component';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { StatusCodes } from 'http-status-codes';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { ConsultantSubmitFailedModalComponent } from '../start-now-app-steps/double-check-step/consultant-submit-failed-modal/consultant-submit-failed-modal.component';
import {
  SubmitSuccessfulModel,
  buildSubmitConsultantRequest,
  getConsultantSubmitErrorMessage,
  getDisplayedErrorMessages,
} from '../start-now-app-steps/double-check-step/double-check-step.helper';

@Component({
  selector: 'app-sna-consultant-submit-component',
  templateUrl: './sna-consultant-submit.component.html',
})
export class SnaConsultantSubmitComponent implements OnInit, OnDestroy {
  @Input() paymentHandler: PaymentHandlerBase;
  @Input() isPaymentProviderFirstLoading: boolean = false;
  @Output() consultantSubmitted: EventEmitter<boolean> = new EventEmitter();

  stepProcessing$: Observable<boolean>;

  readonly DisplayErrorTypes: ConsultantSubmissionErrorType[] = [
    ConsultantSubmissionErrorType.EmailAlreadyExists,
    ConsultantSubmissionErrorType.MissingVoucherDetails,
    ConsultantSubmissionErrorType.VaultImportFailed,
    ConsultantSubmissionErrorType.TransactionFailed,
  ];

  readonly defaultErrorMessage = $localize`Sorry but an error happened during the finalization of
  your application. Please contact Princess House customer service!`;
  readonly loadingWarningMessage = $localize`Please don’t close your browser tab until
  your application is getting finalized! It may take up a couple of minutes`;

  submitConsultantRequest: SubmitConsultantRequest;
  submitSnaStatusCode$: Observable<StatusCodes>;
  areVouchersReceived: boolean = false;

  // Error flags
  isFailedSubmitConsultant: boolean = false;
  isVoucherPaymentError: boolean = false;
  private subscriptions: Subscription = new Subscription();

  @ViewChild('voucherPaymentModal')
  private voucherPaymentModal: OxxoVoucherPaymentModalComponent;

  @ViewChild('errorModal')
  private errorModal: ConsultantSubmitFailedModalComponent;

  constructor(
    private store$: Store<AppState>,
    private loggerService: LoggerService,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.stepProcessing$ = this.store$.select(selectStepProcessing);
    this.submitSnaStatusCode$ = this.store$.select(selectSubmitConsultantStatusCode);
    this.listenSubmitConsultantResponse();
    this.listenReceivedVoucher();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    if (this.isFailedSubmitConsultant) {
      this.store$.dispatch(resetCanSubmitConsultantInfo());
      this.store$.dispatch(resetSubmitConsultantInfo());
    }
  }

  public submit(): void {
    this.isFailedSubmitConsultant = false;

    const startNowAppData$ = combineLatest([
      this.store$.select(selectStartNowAppData),
      this.store$.select(selectPaymentStepSkipped),
    ]).pipe(
      filter((startNowAppData) => !!startNowAppData),
      take(1),
      mergeMap(([startNowAppData, skipPayment]) => {
        this.submitConsultantRequest = buildSubmitConsultantRequest(startNowAppData, skipPayment);
        this.store$.dispatch(resetCanSubmitConsultantInfo());
        this.store$.dispatch(checkIfCanSubmitConsultant({ payload: this.submitConsultantRequest }));

        return of(startNowAppData);
      }),
    );

    const canSubmitConsultantInfo$ = this.store$.select(selectCanSubmitConsultantInfo).pipe(
      filter((canSubmitConsultantInfo) => !!canSubmitConsultantInfo),
      take(1),
    );

    this.subscriptions.add(
      combineLatest([startNowAppData$, canSubmitConsultantInfo$]).subscribe(
        ([startNowAppData, canSubmitConsultantInfo]) => {
          const successfulModel = this.isSubmittableConsultant(canSubmitConsultantInfo);
          if (successfulModel.isSuccessful) {
            if (
              startNowAppData.startNowAppPaymentInfo.paymentType === PaymentType.PayByCash &&
              !this.areVouchersReceived
            ) {
              this.listenVouchersForActivation();
            } else {
              this.submitConsultant();
            }
          } else {
            this.isFailedSubmitConsultant = true;
            this.store$.dispatch(stepProcessing({ stepProcessing: false }));
            this.loggerService.error(
              'CanSubmitConsultant failed!',
              getConsultantSubmitErrorMessage(canSubmitConsultantInfo),
            );
            this.addVoucherLimitErrorIfNeeded(canSubmitConsultantInfo.errorTypes);
            this.openErrorModal(
              getDisplayedErrorMessages(
                this.DisplayErrorTypes,
                canSubmitConsultantInfo.errorTypes,
                this.appInitService.Settings.sna.voucherLimits,
              ),
            );
          }
        },
      ),
    );
  }

  /** Handle the received (activated) voucher modal response. If the vouchers are activated submit
   the consultant otherwise show the corresponding error. */
  public onVoucherModalGotIt() {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectVoucherPaymentInfo),
        this.store$.select(selectCanSubmitConsultantInfo),
      ])
        .pipe(
          filter(
            ([voucherPaymentInfo, canSubmitConsultantInfo]) =>
              canSubmitConsultantInfo !== null && voucherPaymentInfo?.vouchersReceived !== null,
          ),
          take(1),
        )
        .subscribe(([voucherPaymentInfo]) => {
          if (voucherPaymentInfo.vouchersReceived) {
            this.submitConsultant();
          } else {
            this.store$.dispatch(stepProcessing({ stepProcessing: false }));
            this.store$.dispatch(resetReceiveVoucher());
            this.isFailedSubmitConsultant = true;
            this.openErrorModal([
              ConsultantSubmitErrorMessages[ConsultantSubmissionErrorType.MissingVoucherDetails],
            ]);
          }
        }),
    );
  }

  /** Listen created and not received vouchers to activate them. */
  private listenVouchersForActivation() {
    this.subscriptions.add(
      this.store$
        .select(selectVoucherPaymentInfo)
        .pipe(
          filter(
            (voucherPaymentInfo: VoucherPaymentInfo) =>
              !voucherPaymentInfo.vouchersReceived && !!voucherPaymentInfo.vouchers?.length,
          ),
          take(1),
        )
        .subscribe((voucherPaymentInfo) => {
          // Open the modal where the vouchers will be activated on PayPal Oxxo side
          this.voucherPaymentModal.open(voucherPaymentInfo.vouchers);
          this.isVoucherPaymentError = true;
        }),
    );
  }

  /** Listen received (activated) vouchers and set received flag. */
  private listenReceivedVoucher() {
    this.subscriptions.add(
      this.store$
        .select(selectVoucherPaymentInfo)
        .pipe(
          filter((voucherPaymentInfo) => voucherPaymentInfo.vouchersReceived !== null),
          take(1),
        )
        .subscribe((voucherPaymentInfo) => {
          this.areVouchersReceived = voucherPaymentInfo.vouchersReceived;
        }),
    );
  }

  /** Listen submit consultant and handle the result. If is submitted then move to congratulation page
   otherwise show the corresponding error. */
  private listenSubmitConsultantResponse() {
    this.subscriptions.add(
      this.store$
        .select(selectSubmittedConsultantInfo)
        .pipe(filter((submitResult) => !!submitResult))
        .subscribe((submitResult) => {
          const successfulModel = this.isSubmittableConsultant(submitResult);

          if (successfulModel.isSuccessful) {
            this.store$.dispatch(
              updateCongratulationStepMode({ mode: successfulModel.congratulationType }),
            );
            this.consultantSubmitted.emit(true);
          } else {
            this.store$.dispatch(stepProcessing({ stepProcessing: false }));
            if (submitResult.errorTypes?.length) {
              this.addVoucherLimitErrorIfNeeded(submitResult.errorTypes);
            }
            this.openErrorModal(
              getDisplayedErrorMessages(
                this.DisplayErrorTypes,
                submitResult.errorTypes,
                this.appInitService.Settings.sna.voucherLimits,
              ),
            );
            this.isFailedSubmitConsultant = true;
            this.loggerService.error(
              'SubmitConsultant failed!',
              getConsultantSubmitErrorMessage(submitResult),
            );
            this.consultantSubmitted.emit(false);
          }
        }),
    );
  }

  private submitConsultant() {
    if (this.submitConsultantRequest) {
      this.store$.dispatch(resetSubmitConsultantInfo());
      this.store$.dispatch(submitConsultant({ payload: this.submitConsultantRequest }));
    } else {
      this.loggerService.error('Missing request object for submitting consultant');
    }
  }

  private openErrorModal(displayErrorMessages: string[]): void {
    if (!displayErrorMessages.length) {
      displayErrorMessages.push(this.defaultErrorMessage);
    }
    this.errorModal.open(displayErrorMessages);
  }

  private addVoucherLimitErrorIfNeeded(errorTypes: ConsultantSubmissionErrorType[]): void {
    if (errorTypes.includes(ConsultantSubmissionErrorType.YearlyVoucherAmountReached)) {
      this.DisplayErrorTypes.push(ConsultantSubmissionErrorType.YearlyVoucherAmountReached);
    } else if (errorTypes.includes(ConsultantSubmissionErrorType.MonthlyVoucherAmountReached)) {
      this.DisplayErrorTypes.push(ConsultantSubmissionErrorType.MonthlyVoucherAmountReached);
    } else if (errorTypes.includes(ConsultantSubmissionErrorType.DailyVoucherAmountReached)) {
      this.DisplayErrorTypes.push(ConsultantSubmissionErrorType.DailyVoucherAmountReached);
    }
  }

  private isSubmittableConsultant(submitResult: SubmittedConsultantInfo): SubmitSuccessfulModel {
    if (submitResult && submitResult?.errorTypes) {
      const isOnlyOneError = submitResult.errorTypes.length === 1;

      const isContractPaidByVoucher: boolean =
        !!submitResult.errorTypes.find(
          (errorType) => errorType === ConsultantSubmissionErrorType.ContractPaidByVoucher,
        ) && isOnlyOneError;

      const isContractReleaseFailed =
        !!submitResult.errorTypes.find(
          (errorType) => errorType === ConsultantSubmissionErrorType.ContractReleaseFailed,
        ) && isOnlyOneError;

      const isContractPaidByBankTransfer: boolean =
        !!submitResult.errorTypes.find(
          (errorType) => errorType === ConsultantSubmissionErrorType.ContractPaidByBankTransfer,
        ) && isOnlyOneError;

      let congratulationType: CongratulationType = null;
      if (submitResult.isSuccess) {
        congratulationType = CongratulationType.Normal;
      } else if (isContractPaidByVoucher) {
        congratulationType = CongratulationType.Cash;
      } else if (isContractReleaseFailed) {
        congratulationType = CongratulationType.BackOfficeIssue;
      } else if (isContractPaidByBankTransfer) {
        congratulationType = CongratulationType.BankTransfer;
      }

      return {
        isSuccessful:
          submitResult.isSuccess ||
          isContractPaidByVoucher ||
          isContractReleaseFailed ||
          isContractPaidByBankTransfer,
        congratulationType: congratulationType,
      };
    } else {
      return { isSuccessful: false, congratulationType: null };
    }
  }
}
