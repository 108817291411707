import {
  AddPaymentToPartyOrderRequest,
  AddPaymentToPartyOrderResponse,
  LogCardAttemptRequest,
  SubmitOrderRequest,
  SubmitOrderResponse,
} from '@core/dto/order.dto';
import { PhErrorResponse } from '@core/dto/ph-error';
import { Cart } from '@core/store/cart/cart-state-models';
import {
  CCOrderDetails,
  CCOrderHistoryItem,
  FailedOrderItem,
  OrderDetails,
  OrderHistoryItem,
  PartyOrderBaseData,
  PartyOrderData,
  PartyOrderError,
  ShippedCartonDetailsData,
  SubmittedOrderStatus,
} from '@core/store/order/order-state-models';
import { createAction, props } from '@ngrx/store';

export enum OrderActionTypes {
  resetOrderHistoryState = '[Order] Reset order history state',

  fetchOrderHistory = '[Order] Fetch order history',
  fetchOrderHistorySuccess = '[Order] Fetch order history success',
  fetchOrderHistoryFailure = '[Order] Fetch order history failure',

  fetchCCOrderHistory = '[Order] Fetch CC order history',
  fetchCCOrderHistorySuccess = '[Order] Fetch CC order history success',
  fetchCCOrderHistoryFailure = '[Order] Fetch CC order history failure',

  resetOrderDetailsState = '[Order] Reset order details state',

  fetchOrderDetails = '[Order] Fetch order details',
  fetchOrderDetailsSuccess = '[Order] Fetch order details success',
  fetchOrderDetailsFailure = '[Order] Fetch order details failure',

  resetCCOrderDetailsState = '[Order] Reset CC order details state',

  fetchCCOrderDetails = '[Order] Fetch CC order details',
  fetchCCOrderDetailsSuccess = '[Order] CC Fetch order details success',
  fetchCCOrderDetailsFailure = '[Order] CC Fetch order details failure',

  submitOrder = '[Order] Submit Order',
  submitOrderSuccess = '[Order] Submit Order Success',
  submitOrderFailure = '[Order] Submit Order Failure',
  resetSubmittedOrderState = '[Order] Reset Submitted Order State',
  resetIsSubmitDisabledAndErrorType = '[Order] Reset is submit disabled',
  resetFailedOrderItems = '[Order] Reset failedOrderItems',
  setSubmissionStatus = '[Order] Set submission status',
  resetSubmissionStatus = '[Order] Reset submission status',

  fetchPartyOrderData = '[Order] Fetch party order data',
  fetchPartyOrderDataSuccess = '[Order] Fetch party order data success',
  fetchPartyOrderDataFailure = '[Order] Fetch party order data failure',

  storePartyOrderBaseData = '[Order] Store Party Order base data',

  submitPartyOrder = '[Order] Submit party order',
  submitPartyOrderSuccess = '[Order] Submit party order success',
  submitPartyOrderFailure = '[Order] Submit party order failure',

  resetPartyOrderFetched = '[Order] Reset party order fetched',

  logCardAttempt = '[Order] Log card attempt',
  logCardAttemptSuccess = '[Order] Log card attempt success',
  logCardAttemptFailure = '[Order] Log card attempt failure',

  fetchShippedCartonsData = '[Order] Fetch shipped cartons data',
  fetchShippedCartonsSuccess = '[Order] Fetch shipped cartons data success',
  fetchShippedCartonsFailure = '[Order] Fetch shipped cartons data failure',

  resetHasPaymentError = '[Order] Reset hasPaymentError',
}

export const resetOrderHistoryState = createAction(OrderActionTypes.resetOrderHistoryState);
export const fetchOrderHistory = createAction(OrderActionTypes.fetchOrderHistory);
export const fetchOrderHistorySuccess = createAction(
  OrderActionTypes.fetchOrderHistorySuccess,
  props<{ payload: OrderHistoryItem[] }>(),
);
export const fetchOrderHistoryFailure = createAction(
  OrderActionTypes.fetchOrderHistoryFailure,
  props<{ error: PhErrorResponse }>(),
);

export const fetchCCOrderHistory = createAction(OrderActionTypes.fetchCCOrderHistory);
export const fetchCCOrderHistorySuccess = createAction(
  OrderActionTypes.fetchCCOrderHistorySuccess,
  props<{ payload: CCOrderHistoryItem[] }>(),
);
export const fetchCCOrderHistoryFailure = createAction(
  OrderActionTypes.fetchCCOrderHistoryFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetOrderDetailsState = createAction(OrderActionTypes.resetOrderDetailsState);
export const fetchOrderDetails = createAction(
  OrderActionTypes.fetchOrderDetails,
  props<{ orderId: string }>(),
);
export const fetchOrderDetailsSuccess = createAction(
  OrderActionTypes.fetchOrderDetailsSuccess,
  props<{ payload: OrderDetails }>(),
);
export const fetchOrderDetailsFailure = createAction(
  OrderActionTypes.fetchOrderDetailsFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetCCOrderDetailsState = createAction(OrderActionTypes.resetCCOrderDetailsState);
export const fetchCCOrderDetails = createAction(
  OrderActionTypes.fetchCCOrderDetails,
  props<{ poeOrderId: string }>(),
);
export const fetchCCOrderDetailsSuccess = createAction(
  OrderActionTypes.fetchCCOrderDetailsSuccess,
  props<{ payload: CCOrderDetails }>(),
);
export const fetchCCOrderDetailsFailure = createAction(
  OrderActionTypes.fetchCCOrderDetailsFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Submit Order
 **/
export const submitOrder = createAction(
  OrderActionTypes.submitOrder,
  props<{ request: SubmitOrderRequest }>(),
);
export const submitOrderSuccess = createAction(
  OrderActionTypes.submitOrderSuccess,
  props<{
    payload: {
      cart: Cart;
      response: SubmitOrderResponse;
    };
  }>(),
);
export const submitOrderFailure = createAction(
  OrderActionTypes.submitOrderFailure,
  props<{
    error: PhErrorResponse;
    updatedItems: FailedOrderItem[];
    deletedItems: FailedOrderItem[];
  }>(),
);
export const resetSubmittedOrderState = createAction(OrderActionTypes.resetSubmittedOrderState);
export const resetIsSubmitDisabled = createAction(
  OrderActionTypes.resetIsSubmitDisabledAndErrorType,
);
export const resetFailedOrderItems = createAction(OrderActionTypes.resetFailedOrderItems);
export const setSubmissionStatus = createAction(
  OrderActionTypes.setSubmissionStatus,
  props<{ status: SubmittedOrderStatus }>(),
);
export const resetSubmissionStatus = createAction(OrderActionTypes.resetSubmissionStatus);

/**
 * Fetch party order data
 */
export const fetchPartyOrderData = createAction(
  OrderActionTypes.fetchPartyOrderData,
  props<{ orderNumber: string }>(),
);
export const fetchPartyOrderDataSuccess = createAction(
  OrderActionTypes.fetchPartyOrderDataSuccess,
  props<{ partyOrderData: PartyOrderData }>(),
);
export const fetchPartyOrderDataFailure = createAction(
  OrderActionTypes.fetchPartyOrderDataFailure,
  props<{ error: PartyOrderError; status: number }>(),
);

/**
 * Reset party order fetchSuccess
 */
export const resetPartyOrderFetched = createAction(OrderActionTypes.resetPartyOrderFetched);

/**
 * Store Party Order base data
 */
export const storePartyOrderBaseData = createAction(
  OrderActionTypes.storePartyOrderBaseData,
  props<{ payload: PartyOrderBaseData }>(),
);

/**
 * Submit party order
 */
export const submitPartyOrder = createAction(
  OrderActionTypes.submitPartyOrder,
  props<{ request: AddPaymentToPartyOrderRequest }>(),
);
export const submitPartyOrderSuccess = createAction(
  OrderActionTypes.submitPartyOrderSuccess,
  props<{
    payload: AddPaymentToPartyOrderResponse;
  }>(),
);
export const submitPartyOrderFailure = createAction(
  OrderActionTypes.submitPartyOrderFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Log card attempt
 */
export const logCardAttempt = createAction(
  OrderActionTypes.logCardAttempt,
  props<{ request: LogCardAttemptRequest }>(),
);

export const logCardAttemptSuccess = createAction(OrderActionTypes.logCardAttemptSuccess);

export const logCardAttemptFailure = createAction(
  OrderActionTypes.logCardAttemptFailure,
  props<{ error: any }>(),
);

/**
 * Fetch shipped cartons
 */
export const fetchShippedCartonsData = createAction(
  OrderActionTypes.fetchShippedCartonsData,
  props<{ orderUID: string; orderId: string }>(),
);

export const fetchShippedCartonsSuccess = createAction(
  OrderActionTypes.fetchShippedCartonsSuccess,
  props<{ payload: ShippedCartonDetailsData }>(),
);

export const fetchShippedCartonsFailure = createAction(
  OrderActionTypes.fetchShippedCartonsFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Reset hasPaymentError
 */
export const resetHasPaymentError = createAction(OrderActionTypes.resetHasPaymentError);
