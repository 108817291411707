import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { KushkiFormIdTypes } from '@core/enums/kushki-form-id-types.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { LoggerService } from '@core/services/logger.service';
import { CardPaymentMethodHandlerComponent } from '@payment/components/card-payment-method-handler.component';
import { Subject, Subscription } from 'rxjs';
import { DropinInstance } from '../../../core/models/dropin-instance.model';

@Component({
  template: '',
})
export abstract class CardPaymentProviderBaseComponent<T>
  extends CardPaymentMethodHandlerComponent
  implements OnChanges, OnDestroy
{
  @Input() isVisible: boolean = false;
  @Input() paymentToken: string;
  @Input() paymentProvider: PaymentProviderType = PaymentProviderType.Default;
  @Input() cardholderName: CardholderName;
  @Input() disableInput: boolean = false;

  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() dropInComponentInitialized: EventEmitter<
    CardPaymentProviderBaseComponent<DropinInstance>
  > = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _dropInInstance: DropinInstance | undefined;

  /** Third party instance payment request process was successful */
  protected requestPaymentSuccessSubject: Subject<boolean> = new Subject();

  constructor(public loggerService: LoggerService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableInput) this.checkInputFocusability();
  }

  /** Create payment method by card on BE side and stores the result in store*/
  public createPaymentMethod(): void {
    this.paymentHandler.createPaymentMethod();
  }

  public initDropinUiByToken(
    provider: PaymentProviderType,
    cardholderFirstName: string = '',
    cardholderLastName: string = '',
    formIdType?: KushkiFormIdTypes,
  ): Subscription {
    this.loading.emit(true);
    return this.paymentHandler
      .initToken(provider, cardholderFirstName, cardholderLastName, formIdType)
      .subscribe(
        (token) => {
          if (this.dropInContainer.nativeElement) {
            const cardholderName =
              cardholderFirstName && cardholderLastName
                ? `${cardholderFirstName} ${cardholderLastName}`
                : '';
            this.initDropinInstance(
              token,
              cardholderName,
              this.paymentHandler.isCvvRequired(),
              this.paymentHandler.isAvsRequired(),
              this.paymentHandler.collectRiskDataFromInput(),
            );
          } else {
            this.loading.emit(false);
            this.loggerService.error(
              'The payment provider container is empty and can not initialize it by the token!',
            );
          }
        },
        () => {
          this.loading.emit(false);
        },
      );
  }

  /** The instance that is crated by the third party to be able to access their functionalities */
  public abstract get dropInInstance(): T;

  /** The container HTML element in which one the instance is injected */
  public abstract get dropInContainer(): ElementRef<HTMLElement>;

  public abstract initDropinInstance(
    token: string,
    cardholderName: string,
    isCvvRequired?: boolean,
    isAvsRequired?: boolean,
    collectRiskDataFromInput?: boolean,
  ): void;

  public abstract resetToken(cardholderFirstName?: string, cardholderLastName?: string): void;

  /** Disables/enables card payment inputs based on disableInput */
  public checkInputFocusability(): void {
    const inputs = this.getCardPaymentInputs();
    if (inputs === undefined) return;

    if (this.disableInput) {
      inputs.forEach((element) => {
        element.setAttribute('tabindex', '-1');
        element.setAttribute('disabled', 'disabled');
      });
    } else {
      inputs.forEach((element) => {
        element.removeAttribute('tabindex');
        element.removeAttribute('disabled');
      });
    }
  }

  /**
   * @returns all the input elements or iframes used in a card payment that need to be disabled
   */
  public abstract getCardPaymentInputs(): Array<HTMLElement> | undefined;
}
