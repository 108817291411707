import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultantLocatorErrorMessage } from '@core/constants/consultant-locator-error-message';
import { LanguagesLocale } from '@core/constants/locale';
import { FindConsultantRequest, LocateConsultantRequest } from '@core/dto/consultant.dto';
import { ConsultantFinderType } from '@core/enums/consultant-finder-type.enum';
import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { AppState } from '@core/store';
import { selectCountryStates } from '@core/store/address';
import { fetchCountryStates } from '@core/store/address/address.actions';
import { selectConsultantLocatorErrorType } from '@core/store/consultant';
import {
  findConsultant,
  locateConsultant,
  resetConsultantLocatorErrorType,
} from '@core/store/consultant/consultant.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { getFormValidationErrorMessage } from '@shared/utils/validation.utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

enum FinderFormKey {
  firstName = 'firstName',
  lastName = 'lastName',
  city = 'city',
  stateCode = 'stateCode',
  mobilePhoneNumber = 'mobilePhoneNumber',
}

enum LocatorFormKey {
  language = 'language',
  zipCode = 'zipCode',
}

@Component({
  selector: 'app-consultant-finder',
  templateUrl: './consultant-finder.component.html',
  styleUrls: ['./consultant-finder.component.scss'],
})
export class ConsultantFinderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: ConsultantFinderType;
  @Input() zipCode: string = '';
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitted: EventEmitter<FindConsultantRequest | LocateConsultantRequest> =
    new EventEmitter<FindConsultantRequest | LocateConsultantRequest>();

  readonly FinderFormKey = FinderFormKey;
  readonly LocatorFormKey = LocatorFormKey;
  readonly ConsultantFinderType = ConsultantFinderType;
  readonly ConsultantLocatorErrorMessage = ConsultantLocatorErrorMessage;
  languages: SelectOption[] = [...new Set(environment.languages.map((l) => l.code))].map(
    (language) => ({ name: LanguagesLocale[language], value: language }),
  );

  countryStates$: Observable<SelectOption[]>;
  consultantLocatorErrorType$: Observable<ConsultantLocatorErrorType>;
  form: FormGroup;

  getValidationError = getFormValidationErrorMessage;

  private formSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.consultantLocatorErrorType$ = this.store$.select(selectConsultantLocatorErrorType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.valid.emit(false);
    switch (changes.type.currentValue) {
      case ConsultantFinderType.find:
        this.initFindConsultant();
        break;

      case ConsultantFinderType.locate:
        this.initLocateConsultant();
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.store$.dispatch(resetConsultantLocatorErrorType());
  }

  submit = () => {
    if (this.type === ConsultantFinderType.find) {
      this.submitted.emit(this.form.value);
      this.store$.dispatch(findConsultant({ payload: { ...this.form.value, pageNumber: 1 } }));
    } else {
      const request = {
        zipCode: this.form.value.zipCode,
        cultureCode: this.form.value.language,
        pageNumber: 1,
      } as LocateConsultantRequest;
      this.submitted.emit(request);
      this.store$.dispatch(locateConsultant({ payload: request }));
    }
  };

  private initFindConsultant() {
    this.store$.dispatch(fetchCountryStates());
    this.form = this.fb.group(
      {
        [FinderFormKey.firstName]: '',
        [FinderFormKey.lastName]: '',
        [FinderFormKey.city]: '',
        [FinderFormKey.stateCode]: '',
        [FinderFormKey.mobilePhoneNumber]: '',
      },
      { validators: this.customFindValidation },
    );

    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));

    this.subscribeToStatusChanges();
  }

  private customFindValidation(formGroup: FormGroup) {
    return !!formGroup.get(FinderFormKey.firstName).value ||
      !!formGroup.get(FinderFormKey.lastName).value ||
      !!formGroup.get(FinderFormKey.city).value ||
      !!formGroup.get(FinderFormKey.stateCode).value ||
      !!formGroup.get(FinderFormKey.mobilePhoneNumber).value
      ? null
      : { required: true };
  }

  private initLocateConsultant() {
    this.form = this.fb.group({
      [LocatorFormKey.language]: this.languages[0].value,
      [LocatorFormKey.zipCode]: [this.zipCode, Validators.required],
    });
    this.valid.emit(this.form.valid);
    this.subscribeToStatusChanges();
  }

  private subscribeToStatusChanges() {
    this.formSubscription?.unsubscribe();
    this.formSubscription = this.form.statusChanges.pipe(distinctUntilChanged()).subscribe((s) => {
      if (s === 'VALID') {
        this.valid.emit(true);
      } else {
        this.valid.emit(false);
      }
    });
  }
}
