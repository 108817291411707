<div
  ngbRadioGroup
  class="d-md-flex d-block justify-content-between radio-group-container mb-0"
  [ngModel]="selectedPaymentType"
  (ngModelChange)="onChangePaymentType($event)"
>
  <div class="payment-card-container">
    <h6 class="d-none d-md-block radio-group-label text-uppercase ml-5" i18n>
      Credit/debit card
    </h6>
    <label ngbButtonLabel class="my-0 text-uppercase radio-label w-100">
      <div class="d-flex justify-content-between justify-content-md-start">
        <div class="d-flex align-self-center">
          <input
            ngbButton
            class="radio-input"
            type="radio"
            [value]="PaymentType.CreditOrDebitCard"
            [disabled]="isStepProcessing || disabled"
          />
          <h6 class="d-md-none text-left radio-group-label text-uppercase mb-0" i18n>
            Credit/debit card
          </h6>
        </div>
        <div class="d-flex">
          <img class="banc-card-icon" [src]="PaymentImageSrc.visaCard" />
          <img class="banc-card-icon" [src]="PaymentImageSrc.masterCard" />
        </div>
      </div>
    </label>
  </div>
  <div class="payment-card-container">
    <h6 class="d-none d-md-block radio-group-label text-uppercase ml-5" i18n>Paypal</h6>
    <label ngbButtonLabel class="my-0 text-uppercase radio-label w-100">
      <div class="d-flex justify-content-between justify-content-md-start">
        <div class="d-flex align-self-center">
          <input
            ngbButton
            class="radio-input"
            type="radio"
            [value]="PaymentType.PayPal"
            [disabled]="isStepProcessing || disabled"
          />
          <h6 class="d-md-none text-left radio-group-label text-uppercase mb-0" i18n>Paypal</h6>
        </div>
        <div>
          <img class="banc-card-icon" [src]="PaymentImageSrc.paypal" />
        </div>
      </div>
    </label>
  </div>
  <div *ngIf="showVoucherPayment" class="payment-card-container">
    <h6 class="d-none d-md-block radio-group-label text-uppercase ml-5" i18n>Pay by cash</h6>
    <label ngbButtonLabel class="my-0 text-uppercase radio-label w-100">
      <div class="d-flex justify-content-between justify-content-md-start">
        <div class="d-flex align-self-center">
          <input
            class="radio-input align-self-end align-self-center"
            ngbButton
            type="radio"
            [value]="PaymentType.PayByCash"
            [disabled]="isStepProcessing || disabled"
          />
          <h6 class="d-md-none text-left radio-group-label text-uppercase align-self-end mb-0" i18n>
            Pay by cash
          </h6>
        </div>
        <div>
          <img class="pay-by-cash-icon" [src]="PaymentImageSrc.oxxo" />
        </div>
      </div>
    </label>
  </div>
  <div *ngIf="showBankTransferPayment" class="payment-card-container">
    <h6 class="d-none d-md-block radio-group-label text-uppercase ml-5" i18n>Bank transfer</h6>
    <label ngbButtonLabel class="my-0 text-uppercase radio-label w-100">
      <div class="d-flex justify-content-between justify-content-md-start">
        <div class="d-flex align-self-center">
          <input
            class="radio-input align-self-end align-self-center"
            ngbButton
            type="radio"
            [value]="PaymentType.BankTransfer"
            [disabled]="isStepProcessing || disabled"
          />
          <h6 class="d-md-none text-left radio-group-label text-uppercase align-self-end mb-0" i18n>
            Bank transfer
          </h6>
        </div>
        <div>
          <img class="banc-card-icon spei-logo" [src]="PaymentImageSrc.spei" />
        </div>
      </div>
    </label>
  </div>
</div>
