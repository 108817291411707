import { PaymentImageSrc } from '@core/constants/payment.consts';
import { PaymentMethodType } from '@core/enums/payment-method-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';

export function getPaymentProviderType(
  paymentType: PaymentType,
  isNexioPaymentEnabled: boolean,
): PaymentProviderType | undefined {
  if (isUsaEnv) {
    return PaymentProviderType.Braintree;
  }

  if (isMexEnv) {
    switch (paymentType) {
      case PaymentType.CreditOrDebitCard:
        return isNexioPaymentEnabled ? PaymentProviderType.Nexio : PaymentProviderType.Kushki;
      case PaymentType.PayPal:
        return PaymentProviderType.PayPal;
      case PaymentType.BankTransfer:
        return PaymentProviderType.Kushki;
    }
  }

  return PaymentProviderType.Default;
}

export function initPaymentTypeOptionsAndImages(
  isVoucherEnabled: boolean,
  isBankTransferEnabled: boolean,
): PaymentTypeOptionsAndImages {
  const paymentTypeOptions: SelectOption[] = [];
  const availablePaymentMethodsImgSrc: string[] = [];

  paymentTypeOptions.push({
    name: $localize`Visa / Master card`,
    value: PaymentType.CreditOrDebitCard,
  });
  availablePaymentMethodsImgSrc.push(...[PaymentImageSrc.visaCard, PaymentImageSrc.masterCard]);

  if (isMexEnv) {
    paymentTypeOptions.push({ name: $localize`Paypal`, value: PaymentType.PayPal });
    availablePaymentMethodsImgSrc.push(PaymentImageSrc.paypal);
  }

  if (isVoucherEnabled) {
    paymentTypeOptions.push({ name: $localize`OXXO Voucher`, value: PaymentType.PayByCash });
    availablePaymentMethodsImgSrc.push(PaymentImageSrc.oxxo);
  }

  if (isBankTransferEnabled) {
    paymentTypeOptions.push({ name: $localize`Bank transfer`, value: PaymentType.BankTransfer });
    availablePaymentMethodsImgSrc.push(PaymentImageSrc.spei);
  }

  return {
    paymentTypeOptions: paymentTypeOptions,
    availablePaymentMethodsImgSrc: availablePaymentMethodsImgSrc,
  };
}

export interface PaymentTypeOptionsAndImages {
  paymentTypeOptions: SelectOption[];
  availablePaymentMethodsImgSrc: string[];
}

export function getPaymentMethodType(paymentType: PaymentType): PaymentMethodType {
  switch (paymentType) {
    case PaymentType.CreditOrDebitCard:
    case PaymentType.PayPal:
      return PaymentMethodType.CreditOrDebitCard;
    case PaymentType.PayByCash:
      return PaymentMethodType.Voucher;
    case PaymentType.BankTransfer:
      return PaymentMethodType.BankTransfer;
    default:
      return PaymentMethodType.CreditOrDebitCard;
  }
}
