import { OrderSubmissionErrorTypes } from '@core/constants/ec-order-submission-error-types';
import { PaymentErrorTypes } from '@core/constants/ec-payment-error-types';
import produce from 'immer';
import { OrderState, SubmittedOrderStatus } from './order-state-models';
import { OrderActionTypes } from './order.actions';

export const initialState: OrderState = {
  loading: false,
  isSubmitDisabled: false,
  orderHistory: [],
  ccOrderHistory: [],
  orderDetails: null,
  ccOrderDetails: undefined,
  hasPaymentError: false,
  submittedOrder: {
    status: SubmittedOrderStatus.initial,
    submissionErrorType: null,
    submittedOrderId: null,
    cart: null,
    updatedItems: [],
    deletedItems: [],
  },
  partyOrderPayment: {
    fetched: null,
    status: SubmittedOrderStatus.initial,
    partyOrderData: null,
    partyOrderError: null,
    partyOrderBaseData: null,
  },
  shippedCartonDetails: {
    shippedCartonDetailsData: {
      masterOrderId: null,
      backorderItems: [],
      shippedCartons: [],
      pickingSlipDocuments: [],
    },
    shippedCartonError: null,
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case OrderActionTypes.resetOrderHistoryState:
      draft.orderHistory = initialState.orderHistory;
      return draft;

    case OrderActionTypes.fetchOrderHistory:
      draft.loading = true;
      return;

    case OrderActionTypes.fetchOrderHistorySuccess:
      draft.loading = false;
      draft.orderHistory = action.payload;
      return;

    case OrderActionTypes.fetchOrderHistoryFailure:
      draft.loading = false;
      return;

    case OrderActionTypes.fetchCCOrderHistory:
      draft.loading = true;
      return;

    case OrderActionTypes.fetchCCOrderHistorySuccess:
      draft.loading = false;
      draft.ccOrderHistory = action.payload;
      return;

    case OrderActionTypes.fetchCCOrderHistoryFailure:
      draft.loading = false;
      return;

    case OrderActionTypes.resetOrderDetailsState:
      draft.orderDetails = initialState.orderDetails;
      return draft;

    case OrderActionTypes.fetchOrderDetails:
      draft.loading = true;
      draft.orderDetails = initialState.orderDetails;
      return;

    case OrderActionTypes.fetchOrderDetailsSuccess:
      draft.loading = false;
      draft.orderDetails = action.payload;
      return;

    case OrderActionTypes.fetchOrderDetailsFailure:
      draft.loading = false;
      return;

    case OrderActionTypes.resetCCOrderDetailsState:
      draft.ccOrderDetails = initialState.ccOrderDetails;
      return draft;

    case OrderActionTypes.fetchCCOrderDetails:
      draft.loading = true;
      draft.ccOrderDetails = initialState.ccOrderDetails;
      return;

    case OrderActionTypes.fetchCCOrderDetailsSuccess:
      draft.loading = false;
      draft.ccOrderDetails = action.payload;
      return;

    case OrderActionTypes.fetchCCOrderDetailsFailure:
      draft.loading = false;
      return;

    case OrderActionTypes.submitOrder:
      draft.submittedOrder.status = SubmittedOrderStatus.inProgress;
      return;

    case OrderActionTypes.submitOrderSuccess:
      draft.submittedOrder.status = SubmittedOrderStatus.success;
      draft.submittedOrder.submittedOrderId = action.payload.response.orderId;
      draft.submittedOrder.cart = action.payload.cart;
      return;

    case OrderActionTypes.submitOrderFailure:
      draft.submittedOrder.status = SubmittedOrderStatus.failure;
      draft.submittedOrder.submissionErrorType = action.error.error?.ErrorType;
      if (OrderSubmissionErrorTypes.includes(action.error.error?.ErrorType)) {
        draft.isSubmitDisabled = true;
      }
      if (PaymentErrorTypes.includes(action.error.error?.ErrorType)) {
        draft.hasPaymentError = true;
      }
      draft.submittedOrder.updatedItems = action.updatedItems;
      draft.submittedOrder.deletedItems = action.deletedItems;
      return;

    case OrderActionTypes.setSubmissionStatus:
      draft.submittedOrder.status = action.status;
      return;

    case OrderActionTypes.resetSubmissionStatus:
      draft.submittedOrder.status = initialState.submittedOrder.status;
      draft.isSubmitDisabled = initialState.isSubmitDisabled;
      return;

    case OrderActionTypes.resetIsSubmitDisabledAndErrorType:
      draft.isSubmitDisabled = initialState.isSubmitDisabled;
      draft.submittedOrder.submissionErrorType = null;
      return;

    case OrderActionTypes.resetSubmittedOrderState:
      draft.submittedOrder = initialState.submittedOrder;
      return;

    case OrderActionTypes.resetFailedOrderItems:
      draft.submittedOrder.deletedItems = [];
      draft.submittedOrder.updatedItems = [];
      return;

    case OrderActionTypes.fetchPartyOrderData:
      draft.partyOrderPayment = initialState.partyOrderPayment;
      draft.loading = true;
      return;

    case OrderActionTypes.fetchPartyOrderDataSuccess:
      draft.partyOrderPayment.fetched = true;
      draft.partyOrderPayment.partyOrderData = action.partyOrderData;
      draft.loading = false;
      return;

    case OrderActionTypes.fetchPartyOrderDataFailure:
      draft.loading = false;
      draft.partyOrderPayment.fetched = false;
      draft.partyOrderPayment.partyOrderError = action.error;
      return;

    case OrderActionTypes.resetPartyOrderFetched:
      draft.partyOrderPayment.fetched = initialState.partyOrderPayment.fetched;
      draft.partyOrderPayment.partyOrderData = initialState.partyOrderPayment.partyOrderData;
      draft.partyOrderPayment.partyOrderError = initialState.partyOrderPayment.partyOrderError;
      return;

    case OrderActionTypes.storePartyOrderBaseData:
      draft.partyOrderPayment.partyOrderBaseData = action.payload;
      return;

    case OrderActionTypes.submitPartyOrder:
      draft.loading = true;
      draft.partyOrderPayment.status = SubmittedOrderStatus.inProgress;
      return;

    case OrderActionTypes.submitPartyOrderSuccess:
      draft.partyOrderPayment.status = SubmittedOrderStatus.success;
      draft.loading = false;
      return;

    case OrderActionTypes.submitPartyOrderFailure:
      draft.partyOrderPayment.status = SubmittedOrderStatus.failure;
      draft.loading = false;
      return;

    case OrderActionTypes.fetchShippedCartonsData:
      draft.shippedCartonDetails.shippedCartonError = null;
      draft.loading = true;
      return;

    case OrderActionTypes.fetchShippedCartonsSuccess:
      draft.shippedCartonDetails.shippedCartonDetailsData = action.payload;
      draft.loading = false;
      return;

    case OrderActionTypes.fetchShippedCartonsFailure:
      draft.shippedCartonDetails.shippedCartonError = action.error;
      draft.loading = false;
      return;

    case OrderActionTypes.resetHasPaymentError:
      draft.hasPaymentError = initialState.hasPaymentError;
      return;

    default:
      return draft;
  }
}, initialState);
