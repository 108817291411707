import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MobileMedia } from '@core/constants/screen-sizes';
import { MediaSuffix } from '@core/enums/media-suffix.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { ImageDetails } from '@core/models/image.model';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { VimeoVideoDetails } from '@core/models/vimeo-video-details';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectAddToCartConfirmModal } from '@core/store/cart';
import { addToCart } from '@core/store/cart/cart.actions';
import {
  selectMoreFromCollection,
  selectProductDetails,
  selectProductDetailsVideos,
  selectProductRecommendations,
  selectRelatedRecipes,
} from '@core/store/product';
import {
  ProductDetails,
  ProductDetailsItem,
  ProductRecommendation,
} from '@core/store/product/product-state-models';
import {
  fetchMoreFromCollection,
  fetchProductRecommendations,
  fetchRelatedRecipes,
  resetProductDetails,
} from '@core/store/product/product.actions';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';
import { selectWishlist } from '@core/store/wishlist';
import { addToWishList, deleteFromWishlist } from '@core/store/wishlist/wishlist.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { AltTextUtilService } from '@shared/utils/alt-text-util.service';
import { ProductUtilService } from '@shared/utils/product-util.service';

import { MaxProductQty } from '@core/constants/product.const';
import { ProductRoutes } from '@core/constants/routes.const';
import { getRelatedSlidesFromRelatedRecipes } from '@shared/utils/recipe-utils';
import { Observable, Subscription } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ProductReviewModalComponent } from '../product-review-modal/product-review-modal.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  readonly productsRoute = ProductRoutes.Products;
  readonly feature = environment.feature;
  productDetails$: Observable<ProductDetails>;

  productDetailsItem$: Observable<ProductDetailsItem> = this.route.data.pipe(
    map((d) => d?.productDetailsItem),
  );

  recommendations$: Observable<ProductRecommendation[]>;
  relatedRecipes$: Observable<RecipeItem[]>;
  moreFromCollection$: Observable<ProductRecommendation[]>;
  loadingStatus$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  viewAs: string;
  isWishlisted: boolean;
  addToCartLoading$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  productImageSlides$: Observable<ImageDetails[][]>;
  productVideoSlides$: Observable<VimeoVideoDetails[]>;

  moreFromThisCollectionSlides$: Observable<RelatedCarouselSlide[]>;
  youMightAlsoLikeSlides$: Observable<RelatedCarouselSlide[]>;
  tryTheseRecipesSlides$: Observable<RelatedCarouselSlide[]>;

  quantities: number[] = Array(MaxProductQty)
    .fill(0)
    .map((q, i) => i + 1);
  quantity: number = this.quantities[0];

  private subscriptions: Subscription = new Subscription();

  @ViewChild('productReviewModal') private productReviewModal: ProductReviewModalComponent;

  constructor(
    private store$: Store<AppState>,
    private route: ActivatedRoute,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private altTextUtil: AltTextUtilService,
    public productUtil: ProductUtilService,
  ) {
    this.viewAs = this.route.snapshot.queryParamMap.get(RouterQueryParams.viewAs);
  }

  ngOnInit(): void {
    this.isMobile$ = this.breakpointObserver.observe(MobileMedia).pipe(select((o) => o.matches));
    this.productDetails$ = this.store$.select(selectProductDetails);
    this.redirectProductDetailsRoute();
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.loadingStatus$ = this.productDetails$.pipe(select((details) => details.isLoading));

    this.addToCartLoading$ = this.store$
      .select(selectAddToCartConfirmModal)
      .pipe(select((state) => state.loading));
    this.recommendations$ = this.store$.select(selectProductRecommendations);
    this.relatedRecipes$ = this.store$.select(selectRelatedRecipes);
    this.moreFromCollection$ = this.store$.select(selectMoreFromCollection);

    this.productImageSlides$ = this.productDetailsItem$.pipe(
      filter((i) => !!i),
      map((i) => {
        const { name: productName, collection: collectionName } = i;
        return [
          this.altTextUtil.getProductImagesWithAltText(i.primaryImages, {
            productName,
            collectionName,
            suffix: MediaSuffix.primaryImage,
          }),
          ...i.additionalImages.map((image) =>
            this.altTextUtil.getProductImagesWithAltText(image, {
              productName,
              collectionName,
              suffix: MediaSuffix.secondaryImage,
            }),
          ),
        ];
      }),
    );

    this.productVideoSlides$ = this.store$.select(selectProductDetailsVideos).pipe(
      withLatestFrom(this.productDetailsItem$),
      map(([videos, { name: productName, collection: collectionName }]) =>
        videos.map((video) =>
          this.altTextUtil.getVimeoDetailsWithAltText(video, { productName, collectionName }),
        ),
      ),
    );

    this.moreFromThisCollectionSlides$ = this.moreFromCollection$.pipe(
      map((r) => this.productUtil.getRelatedSlidesFromRecommendations(r)),
    );

    this.youMightAlsoLikeSlides$ = this.recommendations$.pipe(
      map((r) => this.productUtil.getRelatedSlidesFromRecommendations(r)),
    );

    this.tryTheseRecipesSlides$ = this.relatedRecipes$.pipe(
      map((r) => getRelatedSlidesFromRelatedRecipes(r)),
    );

    this.subscriptions.add(
      this.store$.select(selectWishlist).subscribe((wishListItems) => {
        const wishlistedItem = wishListItems.find(
          (wishListItem) => wishListItem.sku === this.getSku(),
        );
        if (!!wishlistedItem) {
          this.isWishlisted = true;
        } else {
          this.isWishlisted = false;
        }
      }),
    );

    this.initMoreFromCollection();
    this.initYouMightAlsoLike();
    this.initTryThese();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(resetProductDetails());
  }

  addToCart(product: ProductDetailsItem) {
    this.store$.dispatch(
      addToCart({
        payload: {
          images: product.primaryImages,
          collection: product.collection,
          productName: product.name,
          sku: product.sku,
          discountedPrice: product.discountedPrice,
          price: product.price,
          quantity: this.quantity,
          categoryNames: product.categoryNames,
          type: product.type,
          isPFASItem: product.isPFASItem,
        },
      }),
    );
  }

  toggleWish(sku: string) {
    if (this.isWishlisted) {
      this.store$.dispatch(deleteFromWishlist({ sku }));
    } else {
      this.store$.dispatch(addToWishList({ sku }));
    }
  }

  openReviewModal(): void {
    this.productReviewModal.openModal();
  }

  scrollToReviews(el: HTMLElement) {
    if (this.feature.productReview) {
      el.scrollIntoView();
    }
  }

  private initYouMightAlsoLike() {
    this.subscriptions.add(
      this.productDetailsItem$
        .pipe(filter((item) => item?.displayYouMightAlsoLike))
        .subscribe(() => {
          const sku = this.getSku();
          this.store$.dispatch(fetchProductRecommendations({ sku, date: this.viewAs }));
        }),
    );
  }

  private initTryThese() {
    this.subscriptions.add(
      this.productDetailsItem$.pipe(filter((item) => item?.displayTryThese)).subscribe(() => {
        const sku = this.getSku();
        this.store$.dispatch(fetchRelatedRecipes({ sku, date: this.viewAs }));
      }),
    );
  }

  private initMoreFromCollection() {
    this.subscriptions.add(
      this.productDetailsItem$
        .pipe(filter((item) => item?.displayMoreFromCollection))
        .subscribe(({ collectionUrlName }) => {
          const sku = this.getSku();
          this.store$.dispatch(
            fetchMoreFromCollection({ catalogUrlName: collectionUrlName, sku, date: this.viewAs }),
          );
        }),
    );
  }

  private getSku() {
    const sku = this.route.snapshot.paramMap.get('sku');
    if (sku) {
      return sku;
    }
    const nameAndSkuFromUrl = this.route.snapshot.paramMap.get('nameAndSku');
    const nameAndSku = nameAndSkuFromUrl.split('-');

    return nameAndSku[nameAndSku.length - 1];
  }

  private redirectProductDetailsRoute() {
    const collectionFromUrl = this.route.snapshot.paramMap.get('collection');
    const nameFromUrl = this.route.snapshot.paramMap.get('name');
    const skuFromUrl = this.route.snapshot.paramMap.get('sku');

    this.subscriptions.add(
      this.productDetailsItem$.subscribe((data) => {
        if (data) {
          const desiredUrl = this.productUtil.createProductDetailsUrl(
            data.collectionUrlName,
            data.productUrlName,
            data.sku,
          );
          const actualUrl = this.productUtil.createProductDetailsUrl(
            collectionFromUrl,
            nameFromUrl,
            skuFromUrl,
          );

          if (desiredUrl !== actualUrl) {
            this.location.replaceState(desiredUrl);
          }
        }
      }),
    );
  }
}
