/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CardPaymentTypes, PaymentImageSrc } from '@core/constants/payment.consts';
import { SubmitOrderRequest } from '@core/dto/order.dto';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { KushkiFormIdTypes } from '@core/enums/kushki-form-id-types.enum';
import { EcPaymentHandlerType, PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { AppInitService } from '@core/services/app-init.service';
import { GtmService } from '@core/services/gtm.service';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectCart } from '@core/store/cart';
import { Cart } from '@core/store/cart/cart-state-models';
import { fetchCart } from '@core/store/cart/cart.actions';
import { selectPaymentType } from '@core/store/checkout';
import { setPaymentType, storeBillingAddress } from '@core/store/checkout/checkout.actions';
import { selectCurrentConsultant, selectParty } from '@core/store/consultant';
import {
  selectDeletedOrderItems,
  selectOrderHasPaymentError,
  selectSubmittedOrder,
  selectUpdatedOrderItems,
} from '@core/store/order';
import { FailedOrderItem, SubmittedOrderStatus } from '@core/store/order/order-state-models';
import {
  resetHasPaymentError,
  resetSubmissionStatus,
  setSubmissionStatus,
  submitOrder,
} from '@core/store/order/order.actions';
import {
  selectCreatePaymentMethodResponse,
  selectPaymentInfo,
  selectPaymentLoading,
} from '@core/store/payment';
import { CreatedCardPaymentMethod } from '@core/store/payment/payment-state-models';
import { storeCardPaymentInfo } from '@core/store/payment/payment.actions';
import { selectVoucherStateLoading, selectVouchers } from '@core/store/voucher';
import { createVoucher, setVoucherEmail } from '@core/store/voucher/voucher.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { CardPaymentWrapperComponent } from '@payment/components/card-payment-wrapper/card-payment-wrapper.component';
import { SavedCardInfo } from '@payment/components/saved-card/saved-card-info.model';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import {
  PaymentTypeOptionsAndImages,
  getPaymentProviderType,
  initPaymentTypeOptionsAndImages,
} from '@payment/payment-handler/payment-provider.utils';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { getKushkiFormIdType } from '@shared/utils/get-kushki-fomr-id-type-util';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { FailedOrderSubmitModalComponent } from '../checkout-order-summary/failed-order-submit-modal/failed-order-submit-modal.component';
import { BillingAddressFormComponent } from './billing-address-form/billing-address-form.component';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['../checkout-slide.shared.scss', './checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit, OnDestroy {
  @Output()
  clickNext: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  clickBack: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  nextStepId: number;

  @ViewChild('billingAddress', { static: true })
  billingAddress: BillingAddressFormComponent;

  isMexEnv = isMexEnv;
  paymentProviderType: PaymentProviderType;
  paymentHandler: PaymentHandlerBase;
  updatedOrderItems$: Observable<FailedOrderItem[]>;
  deletedOrderItems$: Observable<FailedOrderItem[]>;
  isVouchersLoading$: Observable<boolean>;
  paymentIsFailed$: Observable<boolean>;
  submissionIsInProgress$: Observable<boolean>;
  paymentTypeOptions: SelectOption[] = [];
  paymentTypeForm: FormGroup;
  selectedPaymentType: PaymentType;
  availablePaymentMethodsImgSrc: string[] = [];
  paymentProviderFirstLoading: boolean = true;
  cardholderName: CardholderName;
  cart$: Observable<Cart>;
  voucherEmail: string;
  isAuthenticated$: Observable<boolean>;
  disabled: boolean = true;
  isPaymentProviderLoading: boolean = true;
  isStepProcessing$: Observable<boolean>;
  hasOrderPaymentError$: Observable<boolean>;
  hasCardPaymentMethodError: boolean = false;
  cardPaymentErrorType: CreateCardPaymentErrorType = CreateCardPaymentErrorType.None;
  cardPaymentMethodInfo: SavedCardInfo;
  selectedCardPaymentType: PaymentCardType = PaymentCardType.DebitCard;
  isCardSaved: boolean = false;
  isPaymentApproved: boolean = false;
  isSubmitted: boolean = false;
  isPayPalCardPaymentEnabled: boolean;
  isNexioPaymentEnabled: boolean;
  kushkiFormIdType: KushkiFormIdTypes;

  readonly PaymentImgSrc = PaymentImageSrc;
  readonly PaymentType = PaymentType;
  readonly isVoucherPaymentEnabled = environment.feature.voucherPaymentEC;
  readonly CardPaymentTypes = CardPaymentTypes;
  readonly PaymentTypeSelector = 'paymentType';

  private subscriptions: Subscription = new Subscription();

  @ViewChild('cardPaymentWrapper') private paymentWrapperComponent: CardPaymentWrapperComponent;
  @ViewChild('failedModal') private failedModal: FailedOrderSubmitModalComponent;

  constructor(
    appInitService: AppInitService,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private cdRef: ChangeDetectorRef,
    private paymentHandlerFactory: PaymentHandlerFactory,
    private fb: FormBuilder,
  ) {
    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.ECommerce,
      EcPaymentHandlerType.CheckoutOrder,
    );

    this.isPayPalCardPaymentEnabled = appInitService.Settings.ec.isPayPalCardPaymentEnabled;
    this.isNexioPaymentEnabled = appInitService.Settings.ec.isNexioCardPaymentEnabled;
  }

  ngOnInit(): void {
    this.initPaymentTypeElements();
    this.initPaymentType();
    this.listenPaymentTypeChange();
    this.store$.dispatch(fetchCart());
    this.cart$ = this.store$.select(selectCart).pipe(filter((x) => !!x));
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.hasOrderPaymentError$ = this.store$.select(selectOrderHasPaymentError);

    this.isStepProcessing$ = this.store$.select(selectPaymentLoading);

    this.listenFailedOrderItems();
    this.submissionIsInProgress$ = this.store$
      .select(selectSubmittedOrder)
      .pipe(map((x) => x.status === SubmittedOrderStatus.inProgress));
    this.paymentIsFailed$ = this.store$
      .select(selectSubmittedOrder)
      .pipe(
        map(
          (x) =>
            x.status === SubmittedOrderStatus.failure &&
            x.submissionErrorType !== PhExceptionErrorType.PipelineException,
        ),
      );

    this.isVouchersLoading$ = this.store$.select(selectVoucherStateLoading);
    this.waitForSubmittedOrder();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get isBillingAddressFormInvalid(): boolean {
    return this.billingAddress?.form?.invalid;
  }

  next(nextStepId: number = this.nextStepId): void {
    if (this.billingAddress.form.valid) {
      if (nextStepId < this.nextStepId) {
        this.back();
        scrollToTop();
      } else {
        this.store$.dispatch(setSubmissionStatus({ status: SubmittedOrderStatus.inProgress }));
        this.billingAddress.form?.markAllAsTouched();
        this.storeBillingAddress();
        this.isSubmitted = true;
        this.selectedPaymentType === PaymentType.PayByCash
          ? this.onNextVoucher()
          : this.onNextCreditCard();
      }
    }
  }

  back(): void {
    this.clickBack.emit();
    scrollToTop();
  }

  onNextVoucher() {
    this.store$.dispatch(setVoucherEmail({ email: this.voucherEmail }));
    this.gtmPayment();
    this.submitOrderWithVoucher();
  }

  onNextCreditCard() {
    if (isUsaEnv) {
      this.subscriptions.add(
        this.paymentWrapperComponent
          .requestPaymentMethodObject()
          .pipe(first())
          .subscribe((isSuccess) => {
            if (isSuccess) {
              this.submitOrderWithCreditCard();
            } else {
              this.store$.dispatch(resetSubmissionStatus());
            }
          }),
      );
    } else {
      if (this.isCardSaved || !(this.disabled || this.hasCardPaymentMethodError)) {
        this.storeCardPaymentInfo();
        this.requestCardPayment();
      }
    }
  }

  toggleDisabled(isPaymentMethodRequestable: boolean): void {
    this.disabled = !isPaymentMethodRequestable;
    // https://github.com/webcomponents/polyfills/issues/238
    this.cdRef.detectChanges();
  }

  onEmailChange(email) {
    this.disabled = !email;
    this.voucherEmail = email;
  }

  isCardFormValid(): boolean {
    return this.isSubmitted ? !this.disabled || this.isCardSaved : true;
  }

  resetCardPayment(previousPaymentType: PaymentType, resetComponent: boolean = false): void {
    this.setCardError(CreateCardPaymentErrorType.None);
    this.store$.dispatch(resetHasPaymentError());
    this.store$.dispatch(resetSubmissionStatus());
    this.isPaymentApproved = false;

    if (isMexEnv && previousPaymentType === PaymentType.PayPal) {
      // As we have already a card payment and we would like to change it we have to reset it
      this.paymentHandler.updateOrder();
      this.paymentHandler.resetToken();
      if (resetComponent) {
        this.resetPayPalComponent();
      }
    }

    this.paymentWrapperComponent.resetToken(
      this.cardholderName?.firstName,
      this.cardholderName?.lastName,
    );
  }

  paymentApproved(isCreated: boolean): void {
    this.isPaymentApproved = isCreated;
    this.cdRef.detectChanges();
  }

  handleIsPaymentProviderLoading(isLoading: boolean): void {
    this.isPaymentProviderLoading = isLoading;
    if (this.paymentProviderFirstLoading && !isLoading) {
      this.paymentProviderFirstLoading = false;
    }
    this.cdRef.detectChanges();
  }

  private initPaymentType() {
    this.store$
      .select(selectPaymentType)
      .pipe(
        take(1),
        filter(
          (paymentType) =>
            !!this.paymentTypeOptions.filter((option) => option.value === paymentType).length,
        ),
      )
      .subscribe((paymentType) => {
        this.selectedPaymentType = paymentType;
        this.paymentTypeForm.patchValue({ [this.PaymentTypeSelector]: paymentType });
        this.kushkiFormIdType = getKushkiFormIdType(paymentType);
        this.paymentProviderType = getPaymentProviderType(paymentType, this.isNexioPaymentEnabled);
      });
  }

  private resetPayPalComponent(): void {
    // need to force change detection for paypal component reinit
    this.isCardSaved = true;
    this.cdRef.detectChanges();
    this.isCardSaved = false;
    this.cdRef.detectChanges();
  }

  requestCardPayment() {
    this.subscriptions.add(
      this.requestCardPaymentMethod().subscribe((response: CreatedCardPaymentMethod) => {
        this.setCardError(response.errorType as CreateCardPaymentErrorType);
        this.cardPaymentMethodInfo = { card: response.card, account: response.account };
        if (response.errorType == CreateCardPaymentErrorType.None) {
          this.submitOrderWithCreditCard();
        } else {
          this.store$.dispatch(resetSubmissionStatus());
        }
      }),
    );
  }

  reInitializeToken(cardholderName: CardholderName): void {
    this.isPaymentProviderLoading = true;
    if (this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
      this.paymentWrapperComponent.resetToken(cardholderName.firstName, cardholderName.lastName);
    }
    this.cardholderName = cardholderName;
  }

  private requestCardPaymentMethod(): Observable<CreatedCardPaymentMethod> {
    const paymentMethodObject$ = this.paymentWrapperComponent.requestPaymentMethodObject().pipe(
      tap((isSuccessful) => {
        if (this.paymentProviderType === PaymentProviderType.PayPal && !isSuccessful) {
          this.store$.dispatch(resetSubmissionStatus());
        }
        this.paymentWrapperComponent.createPaymentMethod(isSuccessful);
      }),
      take(1),
    );
    const listenCreatePaymentMethod$ = this.store$.select(selectCreatePaymentMethodResponse).pipe(
      filter((response: CreatedCardPaymentMethod) => !!response),
      take(1),
    );

    return paymentMethodObject$.pipe(mergeMap(() => listenCreatePaymentMethod$));
  }

  private storeCardPaymentInfo() {
    this.store$.dispatch(
      storeCardPaymentInfo({
        payload: this.selectedCardPaymentType,
      }),
    );
  }

  private setCardError(errorType: CreateCardPaymentErrorType) {
    this.cardPaymentErrorType = errorType;
    this.hasCardPaymentMethodError = errorType !== CreateCardPaymentErrorType.None;
  }

  private gtmPayment(): void {
    this.store$
      .select(selectCart)
      .pipe(take(1))
      .subscribe((cart) => this.gtmService.addPaymentInfo(cart));
  }

  private storeBillingAddress(): void {
    const billingAddressFormValue = this.billingAddress.form.value;
    this.store$.dispatch(
      storeBillingAddress({
        billingInfo: {
          sameAsShippingAddress: billingAddressFormValue.sameAsShippingAddress,
          firstName: billingAddressFormValue.firstName,
          lastName: billingAddressFormValue.lastName,
          address: {
            ...billingAddressFormValue.address,
            country: environment.country,
          },
        },
      }),
    );
  }

  private listenFailedOrderItems(): void {
    this.updatedOrderItems$ = this.store$.select(selectUpdatedOrderItems);
    this.deletedOrderItems$ = this.store$.select(selectDeletedOrderItems);

    this.subscriptions.add(
      combineLatest([this.updatedOrderItems$, this.deletedOrderItems$])
        .pipe(
          filter(([updatedItems, deletedItems]) => !!updatedItems.length || !!deletedItems.length),
          take(1),
        )
        .subscribe(() => {
          this.failedModal.open();
        }),
    );
  }

  private submitOrderWithCreditCard() {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectPaymentInfo).pipe(filter((x) => !!x)),
        this.store$.select(selectCurrentConsultant).pipe(filter((x) => !!x)),
        this.store$.select(selectParty),
      ])
        .pipe(first())
        .subscribe(([paymentInfo, consultant, party]) => {
          const billingInfo = this.billingAddress.form.value;
          const request = {
            consultantBeeNumber: consultant.beeNumber,
            consultantFirstName: consultant.firstName,
            consultantLastName: consultant.lastName,
            nonce: paymentInfo.nonce,
            deviceData: paymentInfo.deviceData,
            consultantEmail: consultant.email,
            consultantPhone: consultant.phoneNumber,
            provider: this.paymentProviderType,
            partyId: party?.partyId,
            hostName: party?.hostName,
            billingFirstName: billingInfo.firstName,
            billingLastName: billingInfo.lastName,
            billingAddress: billingInfo.address,
          } as SubmitOrderRequest;
          this.store$.dispatch(submitOrder({ request }));
        }),
    );
  }

  private submitOrderWithVoucher() {
    this.store$.dispatch(createVoucher({ email: this.voucherEmail }));

    const vouchers$ = this.store$
      .select(selectVouchers)
      .pipe(filter((vouchers) => vouchers.length > 0));

    this.subscriptions.add(
      combineLatest([
        vouchers$,
        this.store$.select(selectCurrentConsultant).pipe(filter((consultant) => !!consultant)),
        this.store$.select(selectParty),
      ])
        .pipe(first())
        .subscribe(([, consultant, party]) => {
          const billingInfo = this.billingAddress.form.value;
          const request = {
            consultantBeeNumber: consultant.beeNumber,
            consultantFirstName: consultant.firstName,
            consultantLastName: consultant.lastName,
            consultantEmail: consultant.email,
            consultantPhone: consultant.phoneNumber,
            partyId: party?.partyId,
            hostName: party?.hostName,
            billingFirstName: billingInfo.firstName,
            billingLastName: billingInfo.lastName,
            billingAddress: billingInfo.address,
            provider: PaymentProviderType.Default,
          } as SubmitOrderRequest;

          this.store$.dispatch(submitOrder({ request }));
        }),
    );
  }

  private waitForSubmittedOrder() {
    this.subscriptions.add(
      this.store$
        .select(selectSubmittedOrder)
        .pipe(
          filter(
            (submittedOrder) =>
              submittedOrder.status === SubmittedOrderStatus.success &&
              !!submittedOrder.submittedOrderId,
          ),
          first(),
        )
        .subscribe(() => {
          this.gtmPayment();
          this.clickNext.emit(this.nextStepId);
          scrollToTop();
        }),
    );
  }

  private initPaymentTypeElements(): void {
    const paymentTypeData: PaymentTypeOptionsAndImages = initPaymentTypeOptionsAndImages(
      environment.feature.voucherPaymentEC,
      environment.feature.bankTransferEc,
    );
    this.paymentTypeOptions = paymentTypeData.paymentTypeOptions;
    this.availablePaymentMethodsImgSrc = paymentTypeData.availablePaymentMethodsImgSrc;
    this.selectedPaymentType = this.paymentTypeOptions[0].value;
    this.kushkiFormIdType = getKushkiFormIdType(this.selectedPaymentType);
    this.paymentProviderType = getPaymentProviderType(
      this.selectedPaymentType,
      this.isNexioPaymentEnabled,
    );
    this.store$.dispatch(setPaymentType({ paymentType: this.selectedPaymentType }));

    this.paymentTypeForm = this.fb.group({
      [this.PaymentTypeSelector]: [this.selectedPaymentType],
    });
  }

  private listenPaymentTypeChange(): void {
    this.subscriptions.add(
      this.paymentTypeForm
        .get(this.PaymentTypeSelector)
        .valueChanges.subscribe((newPaymentType) => {
          this.paymentHandler.resetToken();
          this.disabled = true;
          this.store$.dispatch(setPaymentType({ paymentType: newPaymentType }));
          this.kushkiFormIdType = getKushkiFormIdType(newPaymentType);
          this.paymentProviderType = getPaymentProviderType(
            newPaymentType,
            this.isNexioPaymentEnabled,
          );
          this.selectedPaymentType = newPaymentType;
          if (newPaymentType === PaymentType.PayByCash) {
            this.isPaymentProviderLoading = false;
          }
        }),
    );
  }
}
