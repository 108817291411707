import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Country } from '@core/enums/country.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppcuesService } from '@core/services/appcues.service';
import { AppState } from '@core/store';
import { selectConsultantState } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  selectConsultantFinderStep,
  selectConsultantStepsSkipped,
  selectRegistrationCodeStepSkipped,
  selectSnaPaymentInfo,
  selectStartNowAppData,
} from '@core/store/start-now-app';
import {
  StartNowAppData,
  StartNowAppPaymentInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import {
  fetchDepositPaymentValue,
  fetchIsReducedDepositAvailable,
  fetchIsZeroDepositAvailable,
  fetchReducedDepositPaymentValue,
  storePaymentType,
  updateRegistrationCode,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { NetworkStatus, StartNowAppStep } from '../enums/start-now-app-step.enum';
import { StartNowAppOfflineModalComponent } from './start-now-app-offline-modal/start-now-app-offline-modal.component';

@Component({
  selector: 'app-start-now-app',
  templateUrl: './start-now-app.component.html',
  styleUrls: ['./start-now-app.component.scss'],
})
export class StartNowAppComponent implements OnInit, OnDestroy {
  readonly NavigationSteps: NavigationStep[] = [
    // Consultant Finder Wrapper
    { id: StartNowAppStep.DoYouKnowConsultant },
    { id: StartNowAppStep.SelectConsultant },
    { id: StartNowAppStep.ConfirmConsultant },
    { id: StartNowAppStep.RegistrationCode },
    // SNA steps with progress bar
    { id: StartNowAppStep.AboutYou },
    { id: StartNowAppStep.ConnectWithYou },
    { id: StartNowAppStep.WaitBusinessTools },
    { id: StartNowAppStep.SelectStarterKit },
    { id: StartNowAppStep.Payment },
    { id: StartNowAppStep.PasswordBackofficePortal },
    { id: StartNowAppStep.ConsultantAgreement },
    { id: StartNowAppStep.DoubleCheck },
    { id: StartNowAppStep.Congratulation },
  ];

  consultantFinderSteps: NavigationStep[] = [];

  startNowAppData$: Observable<StartNowAppData>;
  isStartNowAppDisabled: boolean;
  isProwessDown: boolean;
  globalStep: number = StartNowAppStep.DoYouKnowConsultant;
  consultantPhasePassed: boolean = false;
  isFinalized: boolean = false;
  isCurrentConsultantFetchLoading: boolean = true;
  replicatedSiteConsultant: Consultant = null;
  paymentInfo$: Observable<StartNowAppPaymentInfo>;
  country: string = environment.country;
  applicationRegistrationId$: Observable<number>;
  subscriptions: Subscription = new Subscription();
  paymentStepSkipped$: Observable<boolean>;
  skipPayment: boolean = false;
  @ViewChild('offlineModal') private offlineModal: StartNowAppOfflineModalComponent;

  constructor(
    private store$: Store<AppState>,
    private route: ActivatedRoute,
    private appcuesService: AppcuesService,
    private appInitService: AppInitService,
  ) {}

  /** Listen tab closing and show a confirmation pop-up */
  @HostListener('window:beforeunload')
  leavingPageWithoutConfirm(): boolean {
    // in case of congratulation the data is saved we can let the user to close the tab
    return this.isFinalized;
  }

  @HostListener('window:offline', ['$event'])
  offlineEvent(event: Event): void {
    if (event.type === NetworkStatus.Offline) {
      this.offlineModal.open();
    }
  }

  @HostListener('window:online', ['$event'])
  onlineEvent(event: Event): void {
    if (event.type === NetworkStatus.Online) {
      this.offlineModal.close();
    }
  }

  ngOnInit(): void {
    this.listenReplicatedSiteConsultant();
    this.listenFetchedConsultantList();
    this.initializeStartNowAppDisabled();
    this.initializeDefaultPaymentType();

    if (isUsaEnv) {
      this.store$.dispatch(fetchDepositPaymentValue());
      this.store$.dispatch(fetchReducedDepositPaymentValue());
      this.store$.dispatch(fetchIsReducedDepositAvailable());
      this.store$.dispatch(fetchIsZeroDepositAvailable());
    }
    this.paymentInfo$ = this.store$
      .select(selectSnaPaymentInfo)
      .pipe(filter((paymentInfo) => !!paymentInfo));
    this.applicationRegistrationId$ = this.store$.select(selectStartNowAppData).pipe(
      select((startNowAppData) => startNowAppData.applicationRegistrationId),
      filter((applicationRegistrationId) => !!applicationRegistrationId),
    );
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (!!params?.cid) {
        this.store$.dispatch(updateRegistrationCode({ registrationCode: params.cid }));
      } else if (!!params?.lnid) {
        this.store$.dispatch(updateRegistrationCode({ registrationCode: params.lnid }));
      }
    });

    this.subscriptions.add(
      this.appcuesService
        .isAppcuesLoaded()
        .pipe(
          filter((isLoaded) => !!isLoaded),
          take(1),
        )
        .subscribe(() => this.appcuesService.setAppcuesIdentityForAnonymousUser()),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  stepChanged(value: number) {
    this.globalStep = this.globalStep + value;

    if (this.globalStep == StartNowAppStep.Congratulation) {
      this.isFinalized = true;
    }
  }

  goToStep(value: number) {
    this.globalStep = value;
  }

  onConsultantPhaseFinished(): void {
    this.consultantPhasePassed = true;
  }

  onMoveBackToConsultantPhase(): void {
    this.consultantPhasePassed = false;
    combineLatest([
      this.store$.select(selectConsultantStepsSkipped),
      this.store$.select(selectRegistrationCodeStepSkipped),
      this.store$
        .select(selectStartNowAppData)
        .pipe(select((data) => data.selectedConsultant?.isPrivateRecruitment)),
    ])
      .pipe(take(1))
      .subscribe(([consultantStepsSkipped, registrationCodeSkipped, consultantProfileLocked]) => {
        if (consultantStepsSkipped && isMexEnv) {
          this.goToStep(StartNowAppStep.DoYouKnowConsultant);
        } else if (
          window.location.href.includes(RouterQueryParams.cid) ||
          window.location.href.includes(RouterQueryParams.lnid) ||
          registrationCodeSkipped ||
          !consultantProfileLocked
        ) {
          this.goToStep(StartNowAppStep.ConfirmConsultant);
        } else {
          this.stepChanged(-1);
        }
      });
  }

  private listenReplicatedSiteConsultant() {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantState)
        .pipe(
          filter((state) => state.isCurrentConsultantFetched),
          tap((state) => (this.replicatedSiteConsultant = state.currentConsultant)),
          filter((state) => !state.currentConsultant),
          take(1),
        )
        .subscribe(() => {
          this.isCurrentConsultantFetchLoading = false;
          this.globalStep = StartNowAppStep.DoYouKnowConsultant;
          this.consultantFinderSteps = this.NavigationSteps.slice(0, 4);
        }),
    );
  }

  private listenFetchedConsultantList(): void {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantFinderStep)
        .pipe(
          filter((finder) => finder.isConsultantsFetched),
          take(1),
        )
        .subscribe((finder) => {
          if (finder.findIsSuccess && !!this.replicatedSiteConsultant) {
            this.globalStep = StartNowAppStep.ConfirmConsultant;
            this.consultantFinderSteps = this.NavigationSteps.slice(2, 4);
          } else {
            this.consultantFinderSteps = this.NavigationSteps.slice(0, 4);
          }
          this.isCurrentConsultantFetchLoading = false;
        }),
    );
  }

  private initializeStartNowAppDisabled(): void {
    this.isStartNowAppDisabled = this.appInitService.isStartNowAppDisabled();
    this.isProwessDown = this.appInitService.Settings.sna.isProwessDown;
  }

  private initializeDefaultPaymentType(): void {
    this.store$
      .select(selectSnaPaymentInfo)
      .pipe(
        take(1),
        filter((paymentInfo) => !paymentInfo?.paymentType),
      )
      .subscribe(() =>
        this.store$.dispatch(storePaymentType({ paymentType: PaymentType.CreditOrDebitCard })),
      );
  }

  private buildCountryFilter(country: Country): Country {
    // Do not allow for USA consultant to join to a MEX recruiter
    if (country == Country.Usa) return Country.Usa;

    // MEX can join to a USA consultant. If the filter is not defined it will search in MEX and also in USA
    if (country == Country.Mexico) return undefined;
  }
}
