<div class="error-message-container col-12 col-md-10">
  <div class="row">
    <div class="col-8 error-header">
      <span i18n>
        Payment error
        <i class="bi bi-exclamation-triangle"></i>
      </span>
    </div>
    <div class="col-4 change" (click)="resetPaymentClick()">
      <span i18n>Change</span>
    </div>
  </div>
  <div class="error-name" i18n>Something went wrong! Please try again later.</div>
</div>
