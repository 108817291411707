import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AddressValidationErrorMessages } from '@core/constants/address-validation-error-messages';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { CardPaymentTypes, PaymentImageSrc } from '@core/constants/payment.consts';
import { ZipCodeMinimumLength } from '@core/constants/zip-code-minimum-length';
import { AddressType } from '@core/enums/address-type.enum';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { Country } from '@core/enums/country.enum';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { SnaCreateVoucherPaymentErrorType } from '@core/enums/create-voucher-error-type.enum';
import { InputMaxLength } from '@core/enums/input-max-length.enum';
import { KushkiFormIdTypes } from '@core/enums/kushki-form-id-types.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import {
  PaymentStepCardholderNameModel as PaymentStepAdditionalBillingInformationModel,
  PaymentStepAddressModel,
  PaymentStepCardModel,
  PaymentStepPriceModel,
  PaymentStepVoucherModel,
} from '@core/models/start-now-app.model';
import {
  Address,
  AddressVerification,
  OrderSummary,
} from '@core/store/start-now-app/start-now-app-state-models';
import { environment } from '@env';
import { SelectOption } from '@shared/components/select/select.component';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { SavedCardInfo } from '../../../../payment/components/saved-card/saved-card-info.model';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  template: '',
})
export abstract class PaymentStepBaseComponent extends StartNowStepBaseComponent {
  public readonly Country = Country;
  public readonly PaymentType = PaymentType;
  public readonly PaymentCardType = PaymentCardType;
  public readonly CreatePaymentErrorType = CreateCardPaymentErrorType;
  public readonly PaymentImageSrc = PaymentImageSrc;
  public readonly ShowVoucherPayment = environment.feature.voucherPaymentSNA;
  public readonly ShowBankTransfer = environment.feature.bankTransferSna;
  public readonly StatusCodes = StatusCodes;
  public readonly CardPaymentTypes = CardPaymentTypes;
  public readonly PaymentProviderType = PaymentProviderType;

  public readonly FormGroup = {
    PaymentCard: 'paymentCard',
    PaymentPrice: 'paymentPrice',
    PaymentVoucher: 'paymentVoucher',
    PaymentAddress: 'paymentAddress',
    AdditionalBillingInformation: 'additionalBillingInformation',
  };

  public readonly defaultAddress: Address = {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    zip: '',
    addressType: AddressType.Home,
    country: Country.Usa,
    isPostOfficeBox: false,
  };

  public readonly FormControl = {
    CardType: 'cardType',
    Price: 'price',
    AddressLine1: 'address1',
    AddressLine2: 'address2',
    AddressLine3: 'address3',
    City: 'city',
    State: 'state',
    ZipCode: 'zip',
    IsMailingAddress: 'isMailingAddress',
    Email: 'email',
    FirstName: 'firstName',
    LastName: 'lastName',
  };

  // TODO: StartNowApp - make background, fontStyle to be used be defined scss variables
  public readonly PriceInputLabelStyle = {
    'max-width': '369px',
    width: '100%',
    height: '74px',
    background: '#F9F5FB',
    border: '1px solid #EDE0F5',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'right',
  };

  public readonly GetIsMailingAddress: string =
    this.FormGroup.PaymentAddress + '.' + this.FormControl.IsMailingAddress;

  public isPaymentMethodRequestable: boolean = false;
  public isSubmitted: boolean = false;
  public selectedPaymentType: PaymentType;
  public selectedCardPaymentType: PaymentCardType = PaymentCardType.DebitCard;
  public cardPaymentTypeOptions: SelectOption[] = Object.keys(this.CardPaymentTypes).map((key) => ({
    name: this.CardPaymentTypes[key],
    value: key,
  }));
  public voucherEmail: string;
  public paymentFormGroup: FormGroup;
  public showMorePaymentImage: boolean = false;
  public mailingAddress: Address;
  public isCardPaymentError: boolean = false;
  public isVoucherPaymentError: boolean = false;
  public isCardSaved: boolean = false;
  public isSavedKushki: boolean = false;
  public isVoucherSaved: boolean = false;
  public countryStates$: Observable<SelectOption[]>;
  public cardPaymentErrorType: CreateCardPaymentErrorType = CreateCardPaymentErrorType.None;
  public voucherPaymentErrorType: SnaCreateVoucherPaymentErrorType =
    SnaCreateVoucherPaymentErrorType.None;
  public cardPaymentMethodInfo: SavedCardInfo;
  public addressValidation$: Observable<AddressVerification>;
  public isForbiddenState: boolean = false;
  public readonly AddressValidationErrorMessages = AddressValidationErrorMessages;
  public addressValidationResultErrorCode: string;
  public canAddressOverride$: Observable<boolean>;
  public orderSummary$: Observable<OrderSummary>;
  public isStepProcessing$: Observable<boolean>;
  public isPaymentApproved: boolean = false;
  public isHttpErrorOnPayment: boolean = false;
  public isPaymentProviderLoading: boolean = true;
  public statusCode: StatusCodes;
  public createVoucherErrorTypes$: Observable<ConsultantSubmissionErrorType[]>;
  public paymentProvider$: Observable<PaymentProviderType>;
  public isPayPalCardPaymentEnabled: boolean = false;
  public isNexioPaymnetEnabled: boolean = false;
  public canChangeSavedCard$: Observable<boolean>;
  public kushkiFormIdType: KushkiFormIdTypes;

  protected paymentCard: PaymentStepCardModel = {
    cardType: [this.selectedCardPaymentType, [Validators.required]],
  };

  protected paymentPrice: PaymentStepPriceModel = {
    price: ['', []],
  };

  protected paymentVoucher: PaymentStepVoucherModel = {
    email: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
  };

  protected paymentAddress: PaymentStepAddressModel = {
    address1: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
    address2: ['', [Validators.maxLength(InputMaxLength.Fifty)]],
    // TODO: StartNowApp - handle mexico case
    address3: ['', [Validators.maxLength(InputMaxLength.Fifty)]],
    city: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
    state: ['', [Validators.required]],
    zip: [
      '',
      [
        Validators.required,
        Validators.minLength(ZipCodeMinimumLength),
        Validators.maxLength(InputMaxLength.Ten),
      ],
    ],
    isMailingAddress: [true, []],
  };

  protected additionalBillingInformation: PaymentStepAdditionalBillingInformationModel = {
    firstName: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
    lastName: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
  };
}
