<div id="payment-step">
  <h1 class="text-title" i18n>Your best business investment.</h1>

  <h6 class="text-subtitle" i18n>How would you like to pay for your Share + Earn kit?</h6>
  <form [formGroup]="paymentFormGroup">
    <div class="mb-5 row" *ngIf="isMexEnv">
      <div class="col-12 col-md-7">
        <app-payment-type-selector
          [selectedPaymentType]="selectedPaymentType"
          (selectedPaymentTypeChange)="onChangePaymentType($event)"
          [isStepProcessing]="isStepProcessing$ | async"
          [disabled]="isPaymentProviderLoading"
          [showVoucherPayment]="ShowVoucherPayment"
          [showBankTransferPayment]="ShowBankTransfer"
          [hasSavedPayment]="isCardSaved || isVoucherSaved || isPaymentApproved || isSavedKushki"
          (changePaymentType)="onPaymentTypeModalSelection($event)"
        >
        </app-payment-type-selector>
      </div>
      <div class="col-12 col-md-5" [formGroupName]="FormGroup.PaymentPrice">
        <div class="ml-md-2">
          <app-currency-field-input
            [identifier]="FormControl.Price"
            [readonly]="true"
            [isLabelOnInput]="true"
            label="Price"
            i18n-label
            [labelStyle]="FormLabelStyle"
            [inputStyle]="PriceInputLabelStyle"
            [formControlName]="FormControl.Price"
          >
          </app-currency-field-input>
        </div>
      </div>
    </div>

    <!-- Billing Information -->
    <div class="pt-3" [hidden]="selectedPaymentType !== PaymentType.CreditOrDebitCard">
      <h3 class="billing-information-title" i18n>Credit Card Billing Address</h3>
      <div class="row" [formGroupName]="FormGroup.PaymentAddress">
        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="FormControl.IsMailingAddress"
              [formControlName]="FormControl.IsMailingAddress"
            />
            <label
              i18n
              class="form-check-label text-uppercase"
              [for]="FormControl.IsMailingAddress"
            >
              Credit Card Billing Address and Mailing Address are the same
            </label>
          </div>
        </div>
      </div>
      <div class="row my-4 my-md-3" [formGroupName]="FormGroup.AdditionalBillingInformation">
        <div class="col-12 col-md-4">
          <app-text-field-input
            #firstNameInput
            [identifier]="FormControl.FirstName"
            label="Cardholder first name"
            i18n-label
            placeholder="Your first name"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.FirstName"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>
        <div class="col-12 col-md-4">
          <app-text-field-input
            #lastNameInput
            [identifier]="FormControl.LastName"
            label="Cardholder last name"
            i18n-label
            placeholder="Your last name"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.LastName"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>
      </div>

      <div class="row" [formGroupName]="FormGroup.PaymentAddress">
        <div class="col-12 col-md-4 mb-3">
          <app-text-field-input
            [identifier]="FormControl.AddressLine1"
            label="Cardholder Address Line 1"
            i18n-label
            placeholder="Your address"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.AddressLine1"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>

        <div class="col-12 col-md-4 mb-3">
          <app-text-field-input
            [identifier]="FormControl.AddressLine2"
            label="Cardholder Address Line 2"
            i18n-label
            placeholder="Your Address Line 2"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.AddressLine2"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>

        <div *ngIf="isMexEnv" class="col-12 col-md-4 mb-3">
          <app-text-field-input
            [identifier]="AddressLine3"
            label="Cardholder Address Line 3"
            i18n-label
            placeholder="Your Address Line 3"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.AddressLine3"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>
      </div>

      <div class="row bottom-line" [formGroupName]="FormGroup.PaymentAddress">
        <div class="col-12 col-md-4 mb-3">
          <app-text-field-input
            [identifier]="FormControl.City"
            label="Cardholder City"
            i18n-label
            placeholder="Your City"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.City"
            [isDataPrivate]="true"
          ></app-text-field-input>
        </div>

        <div class="col-12 col-md-4 mb-3">
          <app-ph-select
            [identifier]="FormControl.State"
            label="Cardholder State"
            i18n-label
            [labelStyle]="FormLabelStyle"
            i18n-defaultValue
            defaultValue="Your State"
            [formControlName]="FormControl.State"
            [options]="countryStates$ | async"
          ></app-ph-select>
        </div>

        <div class="col-12 col-md-4 mb-3">
          <app-text-field-input
            [identifier]="FormControl.ZipCode"
            label="Cardholder Zip Code"
            i18n-label
            placeholder="Zip Code"
            i18n-placeholder
            [labelStyle]="FormLabelStyle"
            [formControlName]="FormControl.ZipCode"
          ></app-text-field-input>
        </div>
      </div>
    </div>

    <div class="validation-error-message mt-3" *ngIf="!!addressValidationResultErrorCode">
      <strong>
        {{ AddressValidationErrorMessages[addressValidationResultErrorCode] }}
        <i class="bi bi-exclamation-triangle"></i>
      </strong>
    </div>

    <div class="row my-5">
      <div class="col-12" *ngIf="selectedPaymentType === PaymentType.CreditOrDebitCard">
        <h3 class="billing-information-title" i18n>Card Contact Information</h3>
      </div>
      <div
        class="col-12 col-md-6 order-sm-1 order-2"
        [ngClass]="{
          'mt-5': selectedPaymentType !== PaymentType.CreditOrDebitCard,
          'mt-3': selectedPaymentType === PaymentType.CreditOrDebitCard
        }"
      >
        <!-- DEBIT/CREDIT CARD / PAYPAL / BANK TRANSFER -->
        <div
          *ngIf="
            selectedPaymentType === PaymentType.CreditOrDebitCard ||
            selectedPaymentType === PaymentType.PayPal ||
            selectedPaymentType === PaymentType.BankTransfer
          "
        >
          <div [ngClass]="{ 'd-none': isCardPaymentError }">
            <app-card-payment-wrapper
              #cardPaymentWrapper
              *ngIf="!isCardSaved && paymentProvider$ | async"
              payPalTopMargin="mt-1"
              [class]="{
                'd-none': isPaymentApproved || isSavedKushki
              }"
              [cardholderName]="cardholderName"
              [paymentProviderType]="paymentProvider$ | async"
              [paymentHandler]="paymentHandler"
              [disableInput]="disablePaymentInputs"
              [isPayPalCardPaymentEnabled]="isPayPalCardPaymentEnabled"
              [kushkiFormIdType]="kushkiFormIdType"
              [hasKushkiSavedPayment]="isSavedKushki"
              (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
              (isPaymentApproved)="paymentApproved($event)"
              (cardAttempt)="handleCardAttempt($event)"
              (isPaymentProviderLoading)="handleIsPaymentProviderLoading($event)"
            ></app-card-payment-wrapper>

            <div *ngIf="isCardSaved || isPaymentApproved || isSavedKushki">
              <div *ngIf="selectedPaymentType !== PaymentType.BankTransfer; else savedBankTransfer">
                <app-saved-card
                  [cardTypeDisplayValue]="CardPaymentTypes[selectedCardPaymentType]"
                  [cardInfo]="cardPaymentMethodInfo"
                  (resetPayment)="resetCardPayment()"
                  [canChangeSavedCard]="canChangeSavedCard$ | async"
                >
                </app-saved-card>
              </div>
              <ng-template #savedBankTransfer>
                <app-saved-bank-transfer
                  [canChangeSavedBankTransfer]="canChangeSavedCard$ | async"
                  (resetPayment)="resetCardPayment()"
                >
                </app-saved-bank-transfer>
              </ng-template>
            </div>
          </div>

          <div *ngIf="isCardPaymentError" class="mb-5">
            <div *ngIf="selectedPaymentType !== PaymentType.BankTransfer; else bankTransferError">
              <app-create-card-payment-method-error
                [cardTypeDisplayValue]="CardPaymentTypes[selectedCardPaymentType]"
                [cardPaymentErrorType]="cardPaymentErrorType"
                [cardInfo]="cardPaymentMethodInfo.card"
                (resetPayment)="resetCardPayment()"
              ></app-create-card-payment-method-error>
            </div>
            <ng-template #bankTransferError>
              <app-bank-transfer-error
                (resetPayment)="resetCardPayment()"
              ></app-bank-transfer-error>
            </ng-template>
          </div>

          <div *ngIf="!isCardFormValid()" class="validation-error-message">
            <span *ngIf="isUsaEnv" i18n
              >Please fill in the payment related fields with valid values!</span
            >
            <span *ngIf="isMexEnv" i18n
              >Please fill in and submit the payment related fields with valid values!</span
            >
          </div>
        </div>

        <!-- PAY BY CASH -->
        <div
          class="col-12"
          *ngIf="selectedPaymentType === PaymentType.PayByCash && ShowVoucherPayment"
        >
          <div *ngIf="!isVoucherPaymentError" [formGroupName]="FormGroup.PaymentVoucher">
            <div class="row">
              <div class="col-12">
                <app-text-field-input
                  *ngIf="!isVoucherSaved"
                  [identifier]="FormControl.Email"
                  label="Email"
                  i18n-label
                  placeholder="Your email address"
                  i18n-placeholder
                  [formControlName]="FormControl.Email"
                  [isDataPrivate]="true"
                >
                </app-text-field-input>
                <app-saved-voucher
                  *ngIf="isVoucherSaved"
                  [email]="voucherEmail"
                  (resetPayment)="resetVoucherPayment()"
                >
                </app-saved-voucher>
              </div>
            </div>
            <app-voucher-instructions></app-voucher-instructions>
          </div>
          <div *ngIf="isVoucherPaymentError">
            <app-create-voucher-payment-error
              [errorTypes]="createVoucherErrorTypes$ | async"
              [email]="voucherEmail"
              [price]="paymentFormGroup.get(FormGroup.PaymentPrice)?.get(FormControl.Price)?.value"
              (resetPayment)="resetVoucherPayment()"
            >
            </app-create-voucher-payment-error>
          </div>
        </div>
      </div>
      <div *ngIf="isUsaEnv && orderSummary$" class="col-12 col-md-4 order-sm-2 order-1 mt-3">
        <app-order-summary [orderSummary]="orderSummary$ | async"></app-order-summary>
      </div>
    </div>
  </form>

  <app-payment-type-changed-modal
    #paymentTypeChangedModal
    (changePaymentType)="onPaymentTypeModalSelection($event)"
  >
  </app-payment-type-changed-modal>
</div>

<app-sna-address-validation-modal
  [addressValidation$]="addressValidation$"
  [countryStates]="countryStates$ | async"
  [finalizeAddress]="finalizeAddress"
  [isForbiddenState]="isForbiddenState"
  [dismiss]="dismissAddressValidationModal"
></app-sna-address-validation-modal>

<app-sna-consultant-submit-component
  #snaConsultantSubmit
  [paymentHandler]="paymentHandler"
  [isPaymentProviderFirstLoading]="isPaymentProviderFirstLoading"
  (consultantSubmitted)="onConsultantSubmitted($event)"
>
</app-sna-consultant-submit-component>

<div *ngIf="isHttpErrorOnPayment" class="validation-error-message">
  <app-sna-http-status-errors [statusCode]="statusCode"></app-sna-http-status-errors>
</div>
