<div class="card">
  <div
    *ngIf="
      paymentInfo.paymentType === PaymentType.CreditOrDebitCard ||
      paymentInfo.paymentType === PaymentType.PayPal
    "
  >
    <div class="ph-row p-md-2">
      <span class="text-double-dot" i18n>Card Type</span>
      <strong>{{ cardTypeText[paymentInfo.cardPaymentInfo.cardType] }}</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2">
      <span class="text-double-dot" i18n>Card Number</span>
      <strong>XXXX XXXX XXXX {{ paymentInfo.externalPaymentInfo.lastFour }}</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2">
      <span class="text-double-dot" i18n>Cardholder Name</span>
      <strong>{{ paymentInfo.externalPaymentInfo.cardholderName }}</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2">
      <span class="text-double-dot" i18n>Expiration Date</span>
      <strong>
        {{ paymentInfo.externalPaymentInfo.expirationMonth }}-{{
          paymentInfo.externalPaymentInfo.expirationYear
        }}
      </strong>
    </div>
  </div>

  <div *ngIf="paymentInfo.paymentType === PaymentType.PayByCash">
    <div class="ph-row px-md-2 pb-md-2" *ngIf="!skipPayment">
      <span class="text-double-dot" i18n>Payment Type</span>
      <strong i18n>Voucher</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2" *ngIf="!skipPayment">
      <span class="text-double-dot" i18n>Email</span>
      <strong>{{ paymentInfo.voucherPaymentInfo.email }}</strong>
    </div>
  </div>

  <div *ngIf="paymentInfo.paymentType === PaymentType.BankTransfer">
    <div class="ph-row px-md-2 pb-md-2" *ngIf="!skipPayment">
      <span class="text-double-dot" i18n>Payment Type</span>
      <strong i18n>Bank tranfer</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2" *ngIf="!skipPayment">
      <span class="text-double-dot" i18n>Email</span>
      <strong>{{ paymentInfo.createdCardPaymentMethod.email }}</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2" *ngIf="!skipPayment">
      <span class="text-double-dot" i18n>Clabe</span>
      <strong>{{ paymentInfo.createdCardPaymentMethod.clabe }}</strong>
    </div>
  </div>
</div>
