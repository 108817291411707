import { KushkiFormIdTypes } from '@core/enums/kushki-form-id-types.enum';
import { PaymentType } from '@core/enums/payment-type.enum';

export function getKushkiFormIdType(paymentType: PaymentType): KushkiFormIdTypes {
  if (paymentType === PaymentType.CreditOrDebitCard) {
    return KushkiFormIdTypes.Card;
  } else if (paymentType === PaymentType.BankTransfer) {
    return KushkiFormIdTypes.BankTransfer;
  }
  return undefined;
}
