<app-full-page-spinner *ngIf="loading$ | async"></app-full-page-spinner>
<div *ngIf="partyOrderData">
  <app-party-order-payment-order-information></app-party-order-payment-order-information>

  <div *ngIf="showPaymentInformation">
    <!-- Payment type selector -->
    <div class="row my-5 d-none d-md-flex">
      <div *ngIf="isMexEnv" class="col-12 col-md-5">
        <div class="font-weight-bold mb-1">
          <span i18n>Accepted Payment Methods</span>
        </div>
        <div class="d-flex">
          <img
            *ngFor="let imageSrc of availablePaymentMethodsImgSrc"
            class="payment-icon px-1"
            [src]="imageSrc"
          />
        </div>
      </div>
      <div class="col-12 col-md-5" [formGroup]="paymentInfoFormGroup">
        <app-select
          [identifier]="PaymentInfoFormKey.paymentTypeSelector"
          label="Payment Type"
          i18n-label
          [formControlName]="PaymentInfoFormKey.paymentTypeSelector"
          [options]="paymentTypeOptions"
          [readonly]="paymentTypeOptions.length === 1 || isCardSaved"
          [kushkiFormIdType]="kushkiFormIdType"
          (change)="onPaymentTypeChange($event.target.value)"
        ></app-select>
      </div>
    </div>

    <!-- Amount selector -->
    <div class="row">
      <div
        ngbRadioGroup
        class="col-12 col-md-6"
        [ngModel]="selectedPaymentAmountType"
        (ngModelChange)="onChangePaymentAmountType($event)"
        [disabled]="isCardSaved || isKushkiInitialized"
      >
        <h5 i18n class="font-weight-bold text-double-dot my-3">Please select the payment amount</h5>

        <div class="d-none d-md-flex row justify-content-end">
          <div class="col-6">
            <label [for]="PaymentInfoFormKey.fullPaymentAmount" i18n>Balance</label>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <label ngbButtonLabel class="text-uppercase shadow-none radio-button-color p-0 m-0">
              <input
                ngbButton
                class="radio-input"
                type="radio"
                [value]="PaymentAmountType.Balance"
              />
              <span i18n>Full Payment</span>
            </label>
          </div>
          <div class="col-12 col-md-6">
            <div [formGroup]="paymentInfoFormGroup" class="currency-field-input-width">
              <label class="d-md-none" [for]="PaymentInfoFormKey.fullPaymentAmount" i18n
                >Balance</label
              >
              <app-currency-field-input
                [identifier]="PaymentInfoFormKey.fullPaymentAmount"
                labelAsterix="true"
                [formControlName]="PaymentInfoFormKey.fullPaymentAmount"
                [disabled]="true"
              ></app-currency-field-input>
            </div>
          </div>
        </div>
        <div class="d-none d-md-flex row justify-content-end">
          <div class="col-6">
            <label [for]="PaymentInfoFormKey.otherPaymentAmount" i18n>Partial Amount</label>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <label ngbButtonLabel class="text-uppercase shadow-none radio-button-color p-0 m-0">
              <input
                ngbButton
                class="radio-input"
                type="radio"
                [value]="PaymentAmountType.Custom"
              />
              <span i18n>Partial Payment</span>
            </label>
          </div>
          <div class="col-12 col-md-6">
            <div [formGroup]="paymentInfoFormGroup" class="currency-field-input-width">
              <label class="d-md-none" [for]="PaymentInfoFormKey.otherPaymentAmount" i18n>
                Partial Amount
              </label>
              <app-currency-field-input
                [identifier]="PaymentInfoFormKey.otherPaymentAmount"
                labelAsterix="true"
                [formControlName]="PaymentInfoFormKey.otherPaymentAmount"
                placeholder="$0.00"
                [disabled]="isCardSaved || isKushkiInitialized"
              ></app-currency-field-input>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex justify-content-between">
          <h5 class="font-weight-bold my-3" i18n> Payment Information </h5>
          <a
            *ngIf="isKushkiInitialized"
            class="my-3 pointer font-weight-bold underline"
            (click)="enableChangeAmount()"
            i18n
            >Change Amount</a
          >
        </div>

        <ng-container *ngIf="showPaymentProvider" [ngTemplateOutlet]="cardPayment"> </ng-container>
      </div>
    </div>

    <div *ngIf="isAmountGraterThanBalanceDue" class="validation-error-message">
      <span i18n>The amount has to be equal or less than the balance due!!</span>
    </div>
  </div>

  <div class="row mt-4">
    <ng-container *ngIf="!isBackToAddressPage; else backToAddress">
      <div class="col-6 col-md-5">
        <button class="btn btn-outline-dark text-uppercase w-100" (click)="onCancel()" i18n>
          Cancel
        </button>
      </div>
    </ng-container>

    <ng-template #backToAddress>
      <div class="col-6 col-md-5">
        <button
          class="btn btn-outline-dark text-uppercase w-100"
          (click)="onBackToBillingAddressPage()"
          i18n
        >
          Back to Card Billing Address
        </button>
      </div>
    </ng-template>
    <div class="col-6 col-md-5">
      <button
        *ngIf="showPaymentInformation"
        class="btn btn-primary text-uppercase w-100"
        [disabled]="isSubmitDisabled"
        (click)="onSubmit()"
        i18n
      >
        Submit
      </button>
    </div>
  </div>
</div>

<ng-template #cardPayment>
  <div class="row">
    <div class="col-12 col-md-10">
      <app-card-payment-wrapper
        [ngClass]="{ 'd-none': isCardSaved }"
        [paymentHandler]="paymentHandler"
        [paymentProviderType]="paymentProviderType"
        [cardholderName]="cardholderName"
        [isPayPalCardPaymentEnabled]="isPayPalCardPaymentEnabled"
        [(showAddPaymentButtonForKushki)]="showAddPaymentButtonForKushki"
        [kushkiFormIdType]="kushkiFormIdType"
        (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
        (isPaymentApproved)="paymentApproved($event)"
        (cardAttempt)="handleCardAttempt($event)"
        #cardPaymentWrapper
      ></app-card-payment-wrapper>
      <section *ngIf="isCardSaved">
        <div *ngIf="selectedPaymentType !== PaymentTypes.BankTransfer; else savedBankTransfer">
          <app-saved-card
            class="d-flex w-100 mb-5"
            [cardTypeDisplayValue]="CardPaymentTypes[selectedCardPaymentType]"
            (resetPayment)="resetCardPayment(selectedPaymentType, true)"
          >
          </app-saved-card>
        </div>
        <ng-template #savedBankTransfer>
          <app-saved-bank-transfer
            class="d-flex w-100 mb-5"
            (resetPayment)="resetCardPayment(selectedPaymentType, true)"
          ></app-saved-bank-transfer>
        </ng-template>
      </section>
    </div>
  </div>
</ng-template>
